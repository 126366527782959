import { useMemo } from 'react';
import { flattenSummaryFields } from '../../components/brite-viewer/components/benefits/product-layout-constants';
import { recommendationConfigs, transformFields } from './configs';

export const useRecommendations = ({ productId, products, productLayouts }) => {
  const { product, layout } = useMemo(() => {
    const product = products?.find(({ ID }) => ID === productId);
    const layout = productLayouts?.[productId] || {};
    return { product, layout };
  }, [productId]);

  const getCost = () => {
    if (product?.Type === 'dental_buyup' || product?.Type === 'vision_buyup') {
      const idx = layout?.Layout?.Sections?.findIndex(
        ({ DisplayValue }) => DisplayValue === 'Premiums & Contributions'
      );
      if (idx > -1) {
        const cost = layout?.Layout?.Sections?.[idx];
        const field = cost?.Fields?.find(({ PropertyChain }) => PropertyChain === 'Cost');
        return field?.State !== 'hide' ? [field] : [];
      }
    }
    return [];
  };

  const fieldsList = useMemo(() => {
    const list = flattenSummaryFields(product, layout?.Layout);
    let showList = list?.filter((item) => item.State !== 'hide' && item?.itemType !== 'section');
    const cost = getCost();
    return [...cost, ...showList];
  }, [product?.ID]);

  const fieldTransformer = recommendationConfigs?.[product?.Type];

  const config = transformFields(product, fieldsList, fieldTransformer);

  return {
    product,
    layout,
    config,
  };
};
