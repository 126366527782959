import { useState, useEffect } from 'react';
import { CustomAxios } from '../axios/axios';
import { sortBy, cloneDeep } from 'lodash';
import { useStore } from '../components/store-provider/use-store';
import { useDisplaySettings } from '../components/brite-viewer/components/benefits/plan-comparisons/use-display-settings';

// This hook is a copy of the useBenefitsHook in src/api-hooks/use-benefits-hook.js.
// The main differences being the removal of analytics and unnecessary calls that are not needed for the PDF viewer.
export function usePDF({ guideId }) {
  const {
    data: { pages },
    setStore,
  } = useStore();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showPasscodePage, setShowPasscodePage] = useState(false);

  const { BusinessID = '', BenefitsPackageID = '' } = pages?.[0] || {};

  useDisplaySettings(BusinessID, BenefitsPackageID, (displaySettings) => setStore('displaySettings', displaySettings));

  const checkIfIsSmallGroupPlan = (planType) => {
    const acaSmallGroupType = 'ACAPLANS';
    return planType.toUpperCase().startsWith(acaSmallGroupType);
  };

  const checkIfIsIchraPlan = (planType) => {
    const ichraType = 'ICHRA';
    return planType.toUpperCase().startsWith(ichraType);
  };

  const fetchBenefitsPackageV2 = async () => {
    if (!!BenefitsPackageID || !!BusinessID) {
      try {
        const url = `/public/v1/business/${BusinessID}/benefitspackage/${BenefitsPackageID}/product-layout`;
        const { data } = await CustomAxios.get(url);
        const { data: productList } = await CustomAxios.get(
          `/public/v2/business/${BusinessID}/benefitspackage/${BenefitsPackageID}/product`
        );

        let clonedData = cloneDeep(data?.Data || []);

        for (let product of clonedData) {
          for (let section of product?.Layout?.Sections) {
            let combinedFrequencyFields = [];
            for (let field of section?.Fields) {
              if (field.Type === 'visionFrequency') {
                combinedFrequencyFields[combinedFrequencyFields.length - 1].frequency = field;
                combinedFrequencyFields[combinedFrequencyFields.length - 1].Type = 'visionFrequency';
              } else {
                combinedFrequencyFields.push(field);
              }
            }
            section.Fields = combinedFrequencyFields;
          }
        }

        const layouts = clonedData.reduce((prev, item) => ({ ...prev, [item.ProductID]: item }), {});

        const products = productList.reduce(
          (prev, item) => [...prev, { ...item, sections: layouts?.[item?.ID]?.Layout?.Sections || [] }],
          []
        );
        setStore('products', products);
        setStore('productLayouts', layouts);
      } catch (err) {
        console.warn(err);
      }
    }
  };

  const fetchBenefitsPackage = async () => {
    setStore('businessId', BusinessID);
    if (!!BenefitsPackageID && !!BusinessID) {
      try {
        const benefitPackageUrl = `/public/v1/business/${BusinessID}/benefitspackage/${BenefitsPackageID}/product`;
        const { data } = await CustomAxios.get(benefitPackageUrl);
        let list = [];
        for (const item of data) {
          if (item.Type === 'insurance_plan') {
            const url = `/public/v1/plans/${item.ID}`;
            const { data } = await CustomAxios.get(url);
            list.push(data);
          } else {
            list.push(item);
          }
        }
        setStore('benefitsPackage', list);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    fetchBenefitsPackage();
    fetchBenefitsPackageV2();
  }, [pages.length]);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      setLoading(true);
      fetchBenefitsPackage();

      try {
        // Check localstorage for passcode in case they refreshed the page, so we don't have to re-prompt.
        const passcode = localStorage.getItem('passcode');
        if (passcode) {
          CustomAxios.setHeader('X-Brite-Course-Passcode', passcode);
        }

        let url = `/public/v1/course/${guideId}`;

        const result = await CustomAxios.get(url);
        if (!ignore) {
          if (result.data.length === 0) {
            setError(true);
          } else {
            setError(false);
          }

          // Filter out decision and question pages because they are pages only in the brite viewer and not in the PDF viewer.
          let course = sortBy(result.data, ['Order']).filter((page) => page.Type === 'brite-editor');

          let courseDetails;

          let hasAcaPlan = false,
            hasIchraPlan = false,
            hasDecisionToolPage = false,
            hasQuestionPage = false,
            enrollmentURL = '',
            enrollmentLogoURL = '';
          for (let page of course) {
            // set course details from page if we haven't yet
            if (!courseDetails) {
              courseDetails = {
                courseID: page.CourseID,
                courseName: page.CourseName,
                businessName: page.BusinessName,
                businessID: page.BusinessID,
                parentBusinessName: page.ParentBusinessName,
                parentBusinessID: page.ParentBusinessID,
              };
            }

            hasDecisionToolPage = hasDecisionToolPage || page.Type === 'decision';

            if (page.EnrollmentURL) {
              enrollmentURL = page.EnrollmentURL;
              if (enrollmentURL) {
                let url = enrollmentURL;
                if (!url.match(/^http?:\/\//i) && !url.match(/^https?:\/\//i)) {
                  url = 'https://' + url;
                }
                enrollmentURL = url;
              }
              if (page.EnrollmentLogoURL) {
                enrollmentLogoURL = page.EnrollmentLogoURL;
              }
            }
            hasQuestionPage = hasQuestionPage || page.Type === 'question';

            if (page.InsurancePlans && page.InsurancePlans.length > 0) {
              for (let plan of page.InsurancePlans) {
                if (!checkIfIsSmallGroupPlan(plan.PlanType) && !checkIfIsIchraPlan(plan.PlanType)) {
                  continue;
                }

                hasAcaPlan = hasAcaPlan || checkIfIsSmallGroupPlan(plan.PlanType);
                hasIchraPlan = hasIchraPlan || checkIfIsIchraPlan(plan.PlanType);
              }
            }
          }

          setStore('isAcaPlan', hasAcaPlan);
          setStore('isIchraPlan', hasIchraPlan);
          let showFeedback = false;
          if (course.length > 0 && course[0]) {
            showFeedback = course[0].ShowFeedbackPage;
          }

          if (enrollmentURL || showFeedback) {
            // If we don't have the logo for the ben admin, try to auto-populate it based on domain from enrollmentURL.
            // TODO, once the admin portal is prompting the user for the logo, and we have run db migrations to backfill, we can remove this extra request.
            if (enrollmentURL && !enrollmentLogoURL) {
              try {
                const result = await CustomAxios.get(`/public/v1/bp/ben_admin?share_scope=eq.global`, {
                  headers: {
                    'Accept-Profile': 'brite_public',
                  },
                });
                // loop over result.data
                for (let ba of result?.data) {
                  for (let domain of ba.domains) {
                    if (enrollmentURL.includes(domain)) {
                      enrollmentLogoURL = ba.logo_url;
                      break;
                    }
                  }
                }
              } catch (err) {
                // It's not ideal, but okay if we don't have the enrollmentLogoURL, the UI will handle it down the line.
                console.error(err);
              }
            }
            course = [
              ...course,
              {
                Type: 'endOfGuideEnroll',
                ID: 'endOfGuideEnroll',
                Name: `${enrollmentURL ? 'Enroll' : 'Feedback'}`,
                EnrollmentURL: enrollmentURL || '',
                EnrollmentLogoURL: enrollmentLogoURL || '',
                HTMLContent: '',
                Content: '',
                ShowFeedbackPage: showFeedback,
                ...courseDetails,
              },
            ];
          }

          setStore('pages', course);
        }
      } catch (e) {
        console.log(e);
        if (!ignore && e.response && e.response.status === 403) {
          setShowPasscodePage(true);
        } else if (!ignore) {
          setError(true);
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    }

    if (!showPasscodePage) {
      fetchData();
    }

    return () => {
      ignore = true;
    };
  }, [showPasscodePage]);

  return {
    pages,
    loading,
    error,
  };
}
