import React, { useState, useEffect, useRef, createRef, useMemo } from 'react';
import { css } from 'emotion';
import { motion, AnimatePresence } from 'framer-motion';
import { sortBy, groupBy, cloneDeep, uniqBy } from 'lodash';
import { CustomAxios } from '../../axios/axios';

import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import { isToday } from 'date-fns';

import { InfoIconBenefit } from './InfoIconBenefit';

import LinearProgress from '@material-ui/core/LinearProgress';

import { DecisionToolStateQuestion } from './decision-tool-pages/DecisionToolStateQuestion';
import { DecisionToolEnrollingQuestion } from './decision-tool-pages/DecisionToolEnrollingQuestion';

import { DecisionToolDoctorVisitsQuestion } from './decision-tool-pages/DecisionToolDoctorVisitsQuestion';
import { DecisionToolDoctorMedsQuestion } from './decision-tool-pages/DecisionToolDoctorMedsQuestion';
import { DecisionToolUrgentCareQuestion } from './decision-tool-pages/DecisionToolUrgentCareQuestion';
import { DecisionToolBabyQuestion } from './decision-tool-pages/DecisionToolBabyQuestion';
import { DecisionToolSurgeryQuestion } from './decision-tool-pages/DecisionToolSurgeryQuestion';
import { DecisionToolMedicalConditionsQuestion } from './decision-tool-pages/DecisionToolMedicalConditionsQuestion';

import { DecisionToolDisclaimer } from './decision-tool-pages/DecisionToolDisclaimer';
import { RecommendationViewer } from './decision-tool-pages/RecommendationViewer';
import { CustomQuestionsPage } from './decision-tool-pages/CustomQuestionsPage';
import { DecisionToolDentalQuestion } from './decision-tool-pages/DecisionToolDentalQuestion';

import { DecisionToolSTDLTDQuestion } from './decision-tool-pages/DecisionToolSTDLTDQuestion';
import { DecisionToolVisionQuestion } from './decision-tool-pages/DecisionToolVisionQuestion';
import { DecisionToolSUPPQuestion } from './decision-tool-pages/DecisionToolSUPPQuestion';
import { DecisionToolScaleQuestion } from './decision-tool-pages/DecisionToolScaleQuestion';

import { DecisionToolEnrollingACAQuestion } from './decision-tool-pages/DecisionToolEnrollingACAQuestion';

import { DecisionToolMedicalHistoryQuestion } from './decision-tool-pages/DecisionToolMedicalHistoryQuestion';
import posthog from '../../lib/analytics/posthog';

import { BRITE_USER_ANSWERS } from '../decision-tool-views/constants';
import { posthogAttrs } from '../../posthog-constants';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useStore } from '../store-provider/use-store';
import { scrollbar } from '../../shared-components/shared-styles';
import { BriteLanguageSelector } from '../languages';
import { useQuestionDisclaimer } from './decision-tool-pages/useQuestionDisclaimer';

const BriteProgressBar = withStyles((theme) => ({
  root: {
    height: 4,
  },
  colorPrimary: {
    backgroundColor: '#f5f7fa',
  },
  bar: {
    backgroundColor: (props) =>
      props.designStyles?.Theme?.Body?.ButtonColor ? props.designStyles?.Theme?.Body?.ButtonColor : '#25282D',
  },
}))(LinearProgress);

export const DecisionToolViewer = (props) => {
  // fedex-specific feature flag
  const fedexVerbiageChangesFF = useFeatureFlagPayload('depersonalize-premium');

  const { setStore } = useStore();

  const isAca = props.isAcaPlan || props.isIchraPlan;
  const [clearLocal, setClearLocal] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [disclaimerError, setDisclaimerError] = useState(false);

  const [hideButton, setHideButton] = React.useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [disableNextQuestionBtn, setDisableNextQuestionBtn] = useState(false);

  useEffect(() => {
    setCurrentQuestion(1);
    setDisclaimerError(false);
    setDisclaimerAccepted(false);
    setRecommendation(null);
    setRecommendationLoading(false);
    handleSetLivingState('', true);
    handleSetEnrolling(props?.displaySettings?.custom_label_employee, true);
    setPersonalNumberOfficeVisits(0);
    setPersonalNumberSpecialtyVisits(0);
    setPersonalNumberGenericMeds(0);
    setPersonalNumberPreferredMeds(0);
    setPersonalNumberSpecialtyMeds(0);
    setPersonalUrgentCareVisits(0);
    setPersonalERVisits(0);
    setDependantERVisits(0);
    setPersonalBaby(false);
    setDependantBaby(false);
    setPersonalPlannedSurgeries('None');
    setPersonalMedicalConditions('None');
    setDependantMedicalConditions('None');
    setDependantPlannedSurgeries('None');
    setDependantUrgentCareVisits(0);
    setDependantNumberOfficeVisits(0);
    setDependantNumberSpecialtyVisits(0);
    setDependantNumberGenericMeds(0);
    setDependantNumberBrandMeds(0);
    setDependantNumberPreferredMeds(0);
    setDependantNumberSpecialtyMeds(0);
    setZipCode('');
    setPersonalDental(false);
    setDependantDental(false);
    setPersonalSTDLTD('0');
    setPersonalVision(false);
    setDependantVision(false);
    setPersonalSUPP(true);

    setCustomQuestionsAnswers([]);
    setAllCustomProducts([]);
    setAllCoreProducts([]);
    setSpendingAccounts([]);
    setAllAdditionalProducts([]);
    setRecommendedCustomProducts([]);
    setShowRecommendationResults(false);

    setDisableNextQuestionBtn(false);

    if (clearLocal) {
      setClearLocal(false);
      props.clearStoredRecs();
    }
    let answers = [];
    for (let question of props.customQuestions) {
      answers.push({ questionId: question.ID, selectedResponses: [] });
    }
    setCustomQuestionsAnswers(answers);
    setAcaEnrollingMembers(defaultAca);
  }, [props.restartRecommendation]);

  const populateAnswersFromLocalStorage = (answers) => {
    setCurrentQuestion(answers.currentQuestion);
    setLivingState(answers.livingState);
    setEnrolling(answers.enrolling);
    setPersonalNumberOfficeVisits(answers.personalNumberOfficeVisits);
    setPersonalNumberSpecialtyVisits(answers.personalNumberSpecialtyVisits);
    setPersonalNumberGenericMeds(answers.personalNumberGenericMeds);
    setPersonalNumberPreferredMeds(answers.personalNumberPreferredMeds);
    setPersonalNumberSpecialtyMeds(answers.personalNumberSpecialtyVisits);
    setPersonalUrgentCareVisits(answers.personalUrgentCareVisits);
    setPersonalERVisits(answers.personalERVisits);
    setDependantERVisits(answers.dependantERVisits);
    setPersonalBaby(answers.personalBaby);
    setDependantBaby(answers.dependantBaby);
    setPersonalPlannedSurgeries(answers.personalPlannedSurgeries);
    setPersonalMedicalConditions(answers.personalMedicalConditions);
    setDependantMedicalConditions(answers.dependantMedicalConditions);
    setDependantPlannedSurgeries(answers.dependantPlannedSurgeries);
    setDependantUrgentCareVisits(answers.dependantUrgentCareVisits);
    setDependantNumberOfficeVisits(answers.dependantNumberOfficeVisits);
    setDependantNumberSpecialtyVisits(answers.dependantNumberSpecialtyVisits);
    setDependantNumberGenericMeds(answers.dependantNumberGenericMeds);
    setDependantNumberBrandMeds(answers.dependantNumberBrandMeds);
    setDependantNumberPreferredMeds(answers.dependantNumberPreferredMeds);
    setDependantNumberSpecialtyMeds(answers.dependantNumberSpecialtyMeds);
    setZipCode(answers.zipCode);
    setPersonalDental(answers.personalDental);
    setDependantDental(answers.dependantDental);
    setPersonalSTDLTD(answers.personalSTDLTD);
    setPersonalVision(answers.personalVision);
    setDependantVision(answers.dependantVision);
    setPersonalSUPP(answers.personalSUPP);

    setCustomQuestionsAnswers(answers.customQuestionsAnswers);
    setAcaEnrollingMembers(
      answers.acaEnrollingMembers &&
        answers.acaEnrollingMembers.length &&
        answers.acaEnrollingMembers.filter((v) => v.Type.includes('Employee')).length === 0
        ? answers.acaEnrollingMembers
        : defaultAca
    );
  };

  useEffect(() => {
    let path = window.location.pathname;

    let paths = path.split('/');
    let vanityPathIndex = 1;

    for (let p of paths) {
      if (p === 'survey') {
        vanityPathIndex = 2;
      }
    }
    const origRecs = localStorage.getItem(BRITE_USER_ANSWERS + paths[vanityPathIndex]);
    const data = JSON.parse(origRecs) || {};

    const displayRecs = data.updatedAt && isToday(new Date(data.updatedAt));
    if (displayRecs) {
      populateAnswersFromLocalStorage(data);
    } else {
      localStorage.removeItem(BRITE_USER_ANSWERS + paths[vanityPathIndex]);
      props.setUserAnswers(null);
    }
  }, []);

  const storeUserAnswers = () => {
    let path = window.location.pathname;

    let paths = path.split('/');
    let vanityPathIndex = 1;

    for (let p of paths) {
      if (p === 'survey') {
        vanityPathIndex = 2;
      }
    }

    let userAnswers = {
      ...rawAnswers,
      ...{
        acaEnrollingMembers,
        customQuestionsAnswers,
        personalSUPP,
        dependantVision,
        personalVision,
        personalSTDLTD,
        dependantDental,
        personalDental,
        zipCode,
        dependantNumberSpecialtyMeds,
        dependantNumberPreferredMeds,
        dependantNumberBrandMeds,
        dependantNumberGenericMeds,
        dependantNumberSpecialtyVisits,
        dependantNumberOfficeVisits,
        dependantUrgentCareVisits,
        dependantPlannedSurgeries,
        dependantMedicalConditions,
        personalMedicalConditions,
        personalPlannedSurgeries,
        dependantBaby,
        personalBaby,
        dependantERVisits,
        personalERVisits,
        personalUrgentCareVisits,
        personalNumberBrandMeds,
        personalNumberGenericMeds,
        personalNumberPreferredMeds,
        personalNumberSpecialtyMeds,
        personalNumberOfficeVisits,
        personalNumberSpecialtyVisits,
        enrolling,
        livingState,
        currentQuestion,
      },
    };

    let dateex = new Date().toISOString();

    try {
      window.localStorage.setItem(
        BRITE_USER_ANSWERS + paths[vanityPathIndex],
        JSON.stringify({
          ...userAnswers,
          updatedAt: dateex,
        })
      );
    } catch (e) {
      window.localStorage.clear();
    }

    props.setUserAnswers({
      ...userAnswers,
      updatedAt: dateex,
    });

    setStore('acaEnrollingMembers', acaEnrollingMembers);
  };

  const [allCustomProducts, setAllCustomProducts] = React.useState([]);

  const [allCoreProducts, setAllCoreProducts] = React.useState([]);
  const [spendingAccounts, setSpendingAccounts] = React.useState([]);
  const [allAdditionalProducts, setAllAdditionalProducts] = React.useState([]);

  const [recommendedCustomProducts, setRecommendedCustomProducts] = React.useState([]);

  const [customQuestionsAnswers, setCustomQuestionsAnswers] = React.useState(() => {
    let answers = [];
    for (let question of props.customQuestions) {
      answers.push({ questionId: question.ID, selectedResponses: [] });
    }
    return answers;
  });

  React.useEffect(() => {
    let answers = [];
    for (let question of props.customQuestions) {
      answers.push({ questionId: question.ID, selectedResponses: [] });
    }
    setCustomQuestionsAnswers(answers);
  }, [props.customQuestions]);

  const [recommendation, setRecommendation] = React.useState(null);
  const [recommendationLoading, setRecommendationLoading] = React.useState(false);
  const [livingState, setLivingState] = React.useState(() => {
    posthog.unregister('decisionToolState');
    return '';
  });
  const [zipCode, setZipCode] = React.useState('');

  const defaultAca = [
    {
      tempId: new Date().getTime(),
      Age: 0,
      Type: 'Self',
      IsTobaccoUser: false,
    },
  ];
  const [acaEnrollingMembers, setAcaEnrollingMembers] = React.useState(defaultAca);

  const [enrolling, setEnrolling] = React.useState(() => {
    posthog.unregister('decisionToolEnrolling');
    posthog.unregister('decisionToolHasDependents');
    return props.promptOptions.defaultEnrolling;
  });
  const [personalNumberOfficeVisits, setPersonalNumberOfficeVisits] = React.useState(0);
  const [personalNumberSpecialtyVisits, setPersonalNumberSpecialtyVisits] = React.useState(0);
  const [personalNumberGenericMeds, setPersonalNumberGenericMeds] = React.useState(0);
  const [personalNumberBrandMeds, setPersonalNumberBrandMeds] = React.useState(0);

  const [showRecommendationResults, setShowRecommendationResults] = React.useState(false);
  const [personalNumberPreferredMeds, setPersonalNumberPreferredMeds] = React.useState(0);
  const [personalNumberSpecialtyMeds, setPersonalNumberSpecialtyMeds] = React.useState(0);
  const [personalUrgentCareVisits, setPersonalUrgentCareVisits] = React.useState(0);
  const [personalERVisits, setPersonalERVisits] = React.useState(0);
  const [dependantERVisits, setDependantERVisits] = React.useState(0);
  const [personalBaby, setPersonalBaby] = React.useState(false);

  const [personalDental, setPersonalDental] = React.useState(false);
  const [dependantDental, setDependantDental] = React.useState(false);

  const [personalSTDLTD, setPersonalSTDLTD] = React.useState('0');

  const [personalVision, setPersonalVision] = React.useState(false);
  const [dependantVision, setDependantVision] = React.useState(false);

  const [personalSUPP, setPersonalSUPP] = React.useState(true);

  const [rawAnswers, setRawAnswers] = React.useState({});

  const isAcaQuestions = props?.decisionTool === 'aca';

  const [personalPlannedSurgeries, setPersonalPlannedSurgeries] = React.useState('None');
  const [personalMedicalConditions, setPersonalMedicalConditions] = React.useState('None');
  const [dependantMedicalConditions, setDependantMedicalConditions] = React.useState('None');

  const [medicalHistory, setMedicalHistory] = React.useState([]);
  const [dependantPlannedSurgeries, setDependantPlannedSurgeries] = React.useState('None');
  const [dependantBaby, setDependantBaby] = React.useState(false);
  const [dependantUrgentCareVisits, setDependantUrgentCareVisits] = React.useState(0);
  const [dependantNumberOfficeVisits, setDependantNumberOfficeVisits] = React.useState(0);
  const [dependantNumberSpecialtyVisits, setDependantNumberSpecialtyVisits] = React.useState(0);
  const [dependantNumberGenericMeds, setDependantNumberGenericMeds] = React.useState(0);
  const [dependantNumberBrandMeds, setDependantNumberBrandMeds] = React.useState(0);
  const [dependantNumberPreferredMeds, setDependantNumberPreferredMeds] = React.useState(0);
  const [dependantNumberSpecialtyMeds, setDependantNumberSpecialtyMeds] = React.useState(0);

  const questionDisclaimerText = useQuestionDisclaimer();

  const resetDependantAnswers = () => {
    setDependantERVisits(0);
    setDependantUrgentCareVisits(0);

    setDependantMedicalConditions('None');
    setDependantPlannedSurgeries('None');

    setDependantBaby(false);

    setDependantNumberOfficeVisits(0);
    setDependantNumberSpecialtyVisits(0);

    setDependantNumberGenericMeds(0);
    setDependantNumberBrandMeds(0);
    setDependantNumberPreferredMeds(0);
    setDependantNumberSpecialtyMeds(0);

    setDependantDental(false);
    setDependantVision(false);
  };

  const handleSetEnrolling = (enrollingValue, reset) => {
    if (reset) {
      posthog.unregister('decisionToolEnrolling');
      posthog.unregister('decisionToolHasDependents');
      setEnrolling(enrollingValue);
      return;
    }

    posthog.register({
      decisionToolEnrolling: enrollingValue,
      decisionToolHasDependents: enrolling !== props?.displaySettings?.custom_label_employee,
    });
    setEnrolling(enrollingValue);

    if (enrollingValue === props?.displaySettings?.custom_label_employee) {
      resetDependantAnswers();
    }
  };

  const handleSetLivingState = (livingStateValue, reset) => {
    if (reset) {
      posthog.unregister('decisionToolState');
      setLivingState(livingStateValue);
      return;
    }

    posthog.register({
      decisionToolState: livingStateValue,
    });
    setLivingState(livingStateValue);
  };

  const formatAnswers = () => {
    let acaMems = acaEnrollingMembers;

    return {
      ...rawAnswers,
      ...{
        Version: 1,
        State: livingState,
        MedicalHistory: medicalHistory,
        QuestionResponses: !!customQuestionsAnswers
          ? customQuestionsAnswers.map((q) => {
              return {
                QuestionID: q.questionId,
                AnswerIDs: q.selectedResponses.map((a) => a.ID),
              };
            })
          : null,
        Enrolling: {
          EmployeeOnly: !!(enrolling === props?.displaySettings?.custom_label_employee),
          EmployeePlusSpouse: !!(enrolling === props?.displaySettings?.custom_label_employee_spouse),
          EmployeePlusChildren: !!(enrolling === props?.displaySettings?.custom_label_employee_children),
          Family: !!(enrolling === props?.displaySettings?.custom_label_family),
          ACAZipCode: zipCode,
          ACAEnrollees: acaMems,
        },
        EmployeeEnrolling: {
          Enrolling: {
            EmployeeOnly: !!(enrolling === props?.displaySettings?.custom_label_employee),
            EmployeePlusSpouse: !!(enrolling === props?.displaySettings?.custom_label_employee_spouse),
            EmployeePlusChildren: !!(enrolling === props?.displaySettings?.custom_label_employee_children),
            Family: !!(enrolling === props?.displaySettings?.custom_label_family),
          },
          DoctorVisits: Number(personalNumberOfficeVisits) || 0,
          SpecialistsVisits: Number(personalNumberSpecialtyVisits) || 0,
          UrgentCare: Number(personalUrgentCareVisits) || 0,
          EmergencyRoom: Number(personalERVisits) || 0,
          HavingBabyThisYear: personalBaby,
          Surgeries: {
            MinorOutPatient: !!(personalPlannedSurgeries === 'Minor Outpatient'),
            MinorInPatient: !!(personalPlannedSurgeries === 'Minor Inpatient'),
            MajorInPatient: !!(personalPlannedSurgeries === 'Major Inpatient'),
          },
          CurrentTreatment: {
            None: !!(personalMedicalConditions === 'None'),
            KidneyDialysis: !!(personalMedicalConditions === 'Kidney Dialysis'),
            Cancer: !!(personalMedicalConditions === 'Cancer'),
            AutoImmuneDisease: !!(personalMedicalConditions === 'Auto-immune Disorder'),
            LungDisease: !!(
              personalMedicalConditions === 'Lung Disorders' || personalMedicalConditions === 'End-Stage Renal Failure'
            ),
          },
          MedicationGeneric: Number(personalNumberGenericMeds) || 0,
          MedicationBrandName: Number(personalNumberBrandMeds) || 0,
          MedicationPreferredDrugs: Number(personalNumberPreferredMeds) || 0,
          MedicationSpecialty: Number(personalNumberSpecialtyMeds) || 0,
          Dental: {
            Yes: personalDental,
          },
          Vision: {
            Yes: personalVision,
          },
          SupplementalLife: {
            Yes: !personalSUPP,
          },
          Disability: {
            MinimumMonthsEmergencyFund: Number(personalSTDLTD),
          },
        },
        DependentEnrolling: {
          Enrolling: {
            EmployeeOnly: !!(enrolling === props?.displaySettings?.custom_label_employee),
            EmployeePlusSpouse: !!(enrolling === props?.displaySettings?.custom_label_employee_spouse),
            EmployeePlusChildren: !!(enrolling === props?.displaySettings?.custom_label_employee_children),
            Family: !!(enrolling === props?.displaySettings?.custom_label_family),
          },
          DoctorVisits: Number(dependantNumberOfficeVisits) || 0,
          SpecialistsVisits: Number(dependantNumberSpecialtyVisits) || 0,
          UrgentCare: Number(dependantUrgentCareVisits) || 0,
          EmergencyRoom: Number(dependantERVisits) || 0,
          HavingBabyThisYear: dependantBaby,
          Surgeries: {
            Minor: !!(dependantPlannedSurgeries === 'Minor Procedure'),
            MinorOutPatient: !!(dependantPlannedSurgeries === 'Minor Outpatient'),
            MinorInPatient: !!(dependantPlannedSurgeries === 'Minor Inpatient'),
            MajorInPatient: !!(dependantPlannedSurgeries === 'Major Inpatient'),
          },
          CurrentTreatment: {
            None: !!(dependantMedicalConditions === 'None'),
            KidneyDialysis: !!(dependantMedicalConditions === 'Kidney Dialysis'),
            Cancer: !!(dependantMedicalConditions === 'Cancer'),
            AutoImmuneDisease: !!(dependantMedicalConditions === 'Auto-immune Disorder'),
            LungDisease: !!(
              dependantMedicalConditions === 'Lung Disorders' ||
              dependantMedicalConditions === 'End-Stage Renal Failure'
            ),
          },
          MedicationGeneric: Number(dependantNumberGenericMeds) || 0,
          MedicationBrandName: Number(dependantNumberBrandMeds) || 0,
          MedicationPreferredDrugs: Number(dependantNumberPreferredMeds) || 0,
          MedicationSpecialty: Number(dependantNumberSpecialtyMeds) || 0,
          Dental: {
            Yes: dependantDental,
          },
          Vision: {
            Yes: dependantVision,
          },
        },
      },
    };
  };

  const getMedicalRec = async () => {
    if (!disclaimerAccepted) {
      setDisclaimerError(true);
      if (disclaimerRef && disclaimerRef.current) {
        disclaimerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      return;
    }
    setDisclaimerError(false);
    try {
      setShowRecommendationResults(true);
      setRecommendationLoading(true);
      let answers = formatAnswers();

      let response = await CustomAxios.post(`/public/v1/decisionTool/${props.currentPage.CourseID}`, answers);

      let recommendation = response.data;
      let productRecommendations = recommendation.ProductSuggestions;
      let recommendations = recommendation.Suggestions;

      let orderedRec = handleMedicalRecommendations(recommendations, recommendation.Type);

      // fetch telemedicine add on to the plans
      for (let rec of orderedRec) {
        if (!rec.Plan.PlanType.includes('ichra')) {
          try {
            let { data } = await CustomAxios.get(
              `/public/v1/business/${props.currentPage.BusinessID}/plans/${rec.Plan.ID}/programs`
            );
            let list = [];
            for (const item of data) {
              try {
                let logoUrl = '';
                if (item.CarrierID) {
                  const { data } = await CustomAxios.get(`/public/v1/carrier/${item.CarrierID}`);
                  logoUrl = data.LogoURL;
                }
                list.push({ ...item, LogoURL: logoUrl });
              } catch (err) {
                console.log(err);
                list.push({ ...item, LogoURL: '' });
              }
            }

            const filteredArr = list.reduce((acc, current) => {
              const x = acc.find(
                (item) =>
                  item.ProgramType === 'telemedicine' &&
                  item.PlanID === current.PlanID &&
                  current.ProgramType === 'telemedicine'
              );
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            rec.Plan.includedPrograms = filteredArr;
          } catch (e) {
            console.warn(e);
            rec.Plan.includedPrograms = [];
            console.log('no included programs add on');
          }
        } else {
          rec.Plan.includedPrograms = [];
          rec.Plan.telemedicineIncludedProgram = null;
        }
      }
      setRecommendation(orderedRec);

      // only execute this code if product recommendations are available
      if (productRecommendations && productRecommendations.length > 0) {
        // handle core product recs
        let allCoreProductRecs = productRecommendations.filter((p) => {
          if (
            p.Product.Type === 'dental_ortho_buyup' ||
            p.Product.Type === 'supplimental_life' ||
            p.Product.Type === 'basic_life_v2' ||
            p.Product.Type === 'supplemental_life_v2' ||
            p.Product.Type === 'short_term_disability' ||
            p.Product.Type === 'long_term_disability' ||
            p.Product.Type === 'dental_buyup' ||
            p.Product.Type === 'vision_buyup' ||
            p.Product.Type === 'healthcaregov' ||
            (p.Product.Type === 'reference_based_pricing' && p.Suggested) ||
            (p.Product.Type === 'medicare_recommendation' && p.Suggested)
          ) {
            return true;
          }
          return false;
        });
        setAllCoreProducts(allCoreProductRecs);

        // handle spending accounts not attached to the selected recommmendation
        const allSpendingAccounts = productRecommendations.filter(
          (p) => p.Product.Type === 'dependant_care_fsa_buyup' && p.Suggested // must be suggested to get included
        );
        setSpendingAccounts(allSpendingAccounts);

        // handle additional product recs
        let allAdditionalProductRecs = productRecommendations?.filter((p) => {
          if (
            p.Product.Type === 'critical_illness' ||
            p.Product.Type === 'cancer_buyup' ||
            p.Product.Type === 'hospital_indemnity' ||
            p.Product.Type === 'accident_plan'
          ) {
            return true;
          }
          return false;
        });
        setAllAdditionalProducts(allAdditionalProductRecs);

        // handle custom product recommendations
        let allCustomProductsRecs = productRecommendations?.filter((p) => p.Product.Type === 'custom');

        let recommendedCustom = allCustomProductsRecs?.filter((p) => p.Suggested).map((p) => p.Product);
        recommendedCustom = uniqBy(recommendedCustom, 'ID');

        setAllCustomProducts(allCustomProductsRecs.map((p) => p.Product));
        setRecommendedCustomProducts(recommendedCustom);

        const recommendedProducts = productRecommendations?.reduce(
          (prev, item) =>
            item?.Suggested
              ? {
                  ...prev,
                  [item?.Product?.Type]: [...(prev?.[item?.Product?.Type] || []), item],
                }
              : prev,
          {}
        );

        const selectedProducts = Object.entries(recommendedProducts)?.reduce((prev, [type, item]) => {
          return item?.length === 1 || type === 'custom' ? [...prev, item[0]?.Product?.ID] : prev;
        }, []);
        props.setSelectedBenefits(selectedProducts);
      }
    } catch (e) {
      console.error('error getting recommendations', e);
      toast.error('Could not get recommendation. Try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setRecommendationLoading(false);
    }
  };

  const shouldShowMonthlyCosts = () => {
    for (let page of props.pages) {
      if (page.CourseViewTotalsMonthly) {
        return true;
      }
    }
    return false;
  };

  const showDynamicQuestion = (questionType) => {
    if (props.dynamicQuestions && props.dynamicQuestions.length && props.dynamicQuestions.includes(questionType)) {
      return true;
    }
    return false;
  };

  const acaMemberFilledOut = () => {
    if (!props.promptAge) {
      return true;
    }
    for (let member of acaEnrollingMembers) {
      if ((member.Type === 'Single' || member.Type === 'Self') && member.Age === 0) {
        return false;
      }
    }
    return true;
  };

  const syncEnrolling = (members) => {
    let enrolling = props?.displaySettings?.custom_label_employee;
    let hasChild = false;
    let hasSpouse = false;

    for (let member of members) {
      if (member.Type === 'Child') {
        hasChild = true;
      }
      if (member.Type === 'Spouse') {
        hasSpouse = true;
      }
    }

    if (hasChild && hasSpouse) {
      enrolling = props?.displaySettings?.custom_label_family;
    } else if (hasSpouse) {
      enrolling = props?.displaySettings?.custom_label_employee_spouse;
    } else if (hasChild) {
      enrolling = props?.displaySettings?.custom_label_employee_children;
    }

    handleSetEnrolling(enrolling);
  };

  const totalNumberOfQuestions = useMemo(() => {
    let total = 0;
    if (isAcaQuestions) {
      return 2;
    }
    if (props.dynamicQuestions && props.dynamicQuestions.length) {
      total += props.dynamicQuestions.length;
    }
    if (props.customQuestions && props.customQuestions.length && props.customQuestions.length > 0) {
      total += 1;
    }
    return total;
  });

  useEffect(() => {
    let percentage = 0;

    if (totalNumberOfQuestions > 0) {
      percentage = (100 * currentQuestion) / totalNumberOfQuestions;
    }

    setProgressPercentage(percentage);
  }, [props.dynamicQuestions, props.customQuestions, currentQuestion]);

  const elementsRef = useRef(props.dynamicQuestions.map(() => createRef()));

  const customQuestionRef = useRef();
  const disclaimerRef = useRef();

  useEffect(() => {
    // Disable next question button if the user has not selected state, enrolling, or accepted disclaimer.
    if (
      (props.dynamicQuestions?.[currentQuestion - 1] === 'state' && !livingState) ||
      (props.dynamicQuestions?.[currentQuestion - 1] === 'enrolling' && !enrolling) ||
      (props.dynamicQuestions?.length == currentQuestion - 1 && !disclaimerAccepted)
    ) {
      setDisableNextQuestionBtn(true);
    } else {
      setDisableNextQuestionBtn(false);
    }
  }, [props.dynamicQuestions, livingState, enrolling, disclaimerAccepted, currentQuestion]);

  const handleSubmit = () => {
    if (!recommendationLoading) {
      if (props.onRecommendationListPage) {
        props.setShareClicked((old) => !old);
      } else if (currentQuestion < totalNumberOfQuestions) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        storeUserAnswers();
        if (!isAcaQuestions) {
          getMedicalRec();
        } else {
          if (props.onRecommendationListPage) {
            props.setCloseClicked((old) => !old);
          } else {
            props.setDecisionToolOpen(false);
            if (props.oldPageIndex >= 0) {
              props.setCurrentPageIndex(props.oldPageIndex);
            }
          }
        }
      }
    }
  };

  const submissionButtonText = useMemo(() => {
    return isAcaQuestions
      ? currentQuestion < totalNumberOfQuestions
        ? 'Next Question'
        : 'Update Details'
      : recommendationLoading
      ? 'Analyzing...'
      : props.onRecommendationListPage
      ? 'Share Results'
      : currentQuestion < totalNumberOfQuestions
      ? 'Next Question'
      : 'See Results';
  });

  useEffect(() => {
    if (isAcaQuestions !== 'aca') {
      if (
        elementsRef &&
        elementsRef.current &&
        elementsRef.current[currentQuestion - 1] &&
        elementsRef.current[currentQuestion - 1].current
      ) {
        elementsRef.current[currentQuestion - 1].current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      if (
        currentQuestion === totalNumberOfQuestions &&
        props.customQuestions &&
        props.customQuestions.length &&
        props.customQuestions.length > 0 &&
        customQuestionRef &&
        customQuestionRef.current
      ) {
        customQuestionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [isAcaQuestions, currentQuestion]);

  // Set enrolling to include employee if cost tiers feature flag is enabled, since the checkbox will already be selected.
  useEffect(() => {
    setEnrolling(props?.displaySettings?.custom_label_employee);
  }, []);

  const shouldDisplayDependentQuestions = acaEnrollingMembers?.some((m) => m.Type !== 'Self');
  return (
    <div
      className={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: white;
        overflow: hidden;
        z-index: 1999;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: hidden;
        `}
      >
        <header
          className={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${props.isDesktop ? '8px' : '4px'};
            max-height: 80px;
            border-bottom: 1px solid #e8edf3;
            min-height: ${props.isDesktop ? '65px' : '55px'};
          `}
        >
          <div
            className={css`
              display: flex;
              align-items: center;
              cursor: pointer;
            `}
            onClick={() => {
              storeUserAnswers();
              if (props.onRecommendationListPage) {
                props.setCloseClicked((old) => !old);
              } else {
                props.setDecisionToolOpen(false);

                if (props.oldPageIndex >= 0) {
                  props.setCurrentPageIndex(props.oldPageIndex);
                }
              }
            }}
          >
            {props.logoUrl && props.isDesktop && (
              <img
                alt="Logo"
                src={props.logoUrl}
                className={css`
                  max-height: 54px;
                `}
              />
            )}

            {props.logoUrl && !props.isDesktop && (
              <img
                alt="Logo"
                src={props.logoUrl}
                className={css`
                  padding-left: 12px;

                  max-height: 32px;
                `}
              />
            )}
          </div>

          <div
            className={css`
              display: flex;
              align-items: center;
              gap: 24px;
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 114%;
              /* identical to box height, or 114% */

              letter-spacing: 0.15px;

              color: #25282d;
              padding-right: 24px;
              padding-left: 24px;
            `}
          >
            <BriteLanguageSelector
              guideID={props?.currentPage?.CourseID}
              changeLanguageCallback={props.changeLanguageCallback}
            />

            <Button
              style={{
                padding: `${props.isDesktop ? '12px 42px' : '8px 16px'}`,

                borderRadius: '8px',
                minWidth: `${props.isDesktop ? '188px' : '140px'}`,
              }}
              role="nav"
              variant="outlined"
              aria-label="Close"
              className="page-nav-prev"
              data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
              data-ph-capture-attribute-action={posthogAttrs?.actions?.BACK_TO_GUIDE}
              onClick={() => {
                storeUserAnswers();
                if (props.onRecommendationListPage) {
                  props.setCloseClicked((old) => !old);
                } else {
                  props.setDecisionToolOpen(false);
                  if (props.oldPageIndex >= 0) {
                    props.setCurrentPageIndex(props.oldPageIndex);
                  }
                }
              }}
            >
              Back to Guide
            </Button>
            {props.isDesktop && (
              <>
                <Button
                  style={{
                    padding: '12px 32px',

                    backgroundColor: props.onRecommendationListPage
                      ? !props?.currentPage?.EnrollmentURL
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                          ? props.designStyles?.Theme?.Body?.ButtonColor
                          : '#25282D'
                        : 'transparent'
                      : props.designStyles?.Theme?.Body?.ButtonColor
                      ? props.designStyles?.Theme?.Body?.ButtonColor
                      : '#25282D',
                    color: props.onRecommendationListPage
                      ? props?.currentPage?.EnrollmentURL
                        ? '#25282D'
                        : 'var(--button-text-color)'
                      : 'var(--button-text-color)',
                    minWidth: '188px',

                    borderRadius: '8px',
                  }}
                  id="footer"
                  variant={`${
                    props.onRecommendationListPage
                      ? props?.currentPage?.EnrollmentURL
                        ? 'outlined'
                        : 'contained'
                      : 'contained'
                  }`}
                  role="nav"
                  className="page-nav-next"
                  onClick={handleSubmit}
                  disabled={recommendationLoading || disableNextQuestionBtn}
                  aria-label="See recommendation results"
                  data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
                  data-ph-capture-attribute-action={posthogAttrs?.actions?.SEE_RESULTS}
                >
                  {submissionButtonText}
                </Button>

                {props?.currentPage?.EnrollmentURL && props.onRecommendationListPage && (
                  <Button
                    style={{
                      padding: props.isDesktop ? '12px 42px' : '12px 12px',
                      backgroundColor: props.designStyles?.Theme?.Body?.ButtonColor
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                        : '#25282D',
                      color: '#fff',

                      minWidth: '188px',
                    }}
                    id="footer"
                    variant="contained"
                    role="nav"
                    className="page-nav-next"
                    onClick={() => {
                      props.toggleEnrolledClicked();
                    }}
                  >
                    Enroll Now
                  </Button>
                )}
              </>
            )}
          </div>
        </header>

        {!showRecommendationResults && !isAcaQuestions && (
          <div
            className={css`
              width: 100%;
            `}
          >
            <BriteProgressBar variant="determinate" value={progressPercentage} designStyles={props.designStyles} />
          </div>
        )}

        <main
          className={css`
            flex: auto;
            overflow-y: scroll;
            ${scrollbar.style}
            background: #f5f7fa;
          `}
        >
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ ease: 'easeOut', duration: 2 }}
            className={css`
              text-align: center;
              width: 100%;
              display: flex;
              flex-direction: column;
            `}
          >
            <section
              className={css`
                button {
                  max-width: 260px;
                }
                width: 100%;
              `}
            >
              <div
                className={css`
                  max-width: 750px;
                  margin: 0 auto;
                  padding: 12px;
                `}
              >
                <AnimatePresence>
                  {!showRecommendationResults && (
                    <>
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 700;
                          font-size: 24px;
                          line-height: 32px;
                          /* or 133% */

                          color: #25282d;
                          text-align: left;
                          margin-bottom: 24px;
                          margin-top: 24px;
                        `}
                      >
                        Answer a few questions to get your custom benefit recommendations.
                      </div>

                      {props.dynamicQuestions.map((question, index) => {
                        if (index < currentQuestion) {
                          if (question === 'state') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolStateQuestion
                                  zipCode={zipCode}
                                  setZipCode={(zip) => setZipCode(zip)}
                                  livingState={livingState}
                                  setLivingState={(state) => handleSetLivingState(state)}
                                />
                              </div>
                            );
                          }

                          if (question === 'enrolling') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                {isAca && (
                                  <DecisionToolEnrollingACAQuestion
                                    promptOptions={props.promptOptions}
                                    acaEnrollingMembers={acaEnrollingMembers}
                                    setAcaEnrollingMembers={(members) => {
                                      setAcaEnrollingMembers(members);
                                      syncEnrolling(members);
                                    }}
                                  />
                                )}

                                {!isAca && (
                                  <DecisionToolEnrollingQuestion
                                    acaEnrollingMembers={acaEnrollingMembers}
                                    setAcaEnrollingMembers={(members) => {
                                      setAcaEnrollingMembers(members);
                                      syncEnrolling(members);
                                    }}
                                    isSurvey={props.isSurvey}
                                    enrolling={enrolling}
                                    displaySettings={props.displaySettings}
                                    setEnrolling={(value) => handleSetEnrolling(value)}
                                  />
                                )}
                              </div>
                            );
                          }

                          if (question === 'short_term_disability') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolSTDLTDQuestion
                                  isSurvey={props.isSurvey}
                                  setValue={(option) => setPersonalSTDLTD(option)}
                                  value={personalSTDLTD}
                                  type={'personal'}
                                />
                              </div>
                            );
                          }

                          if (question === 'family_history') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolMedicalHistoryQuestion
                                  setMedicalHistory={(e) => setMedicalHistory(e.target.value)}
                                  medicalHistory={medicalHistory}
                                  questionDisclaimerText={questionDisclaimerText}
                                />
                              </div>
                            );
                          }

                          if (question === 'supplimental_life') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolSUPPQuestion
                                  isSurvey={props.isSurvey}
                                  setValue={(option) => setPersonalSUPP(option)}
                                  value={personalSUPP}
                                  type={'personal'}
                                />
                              </div>
                            );
                          }

                          if (question === 'doctor_visits') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolDoctorVisitsQuestion
                                  isSurvey={props.isSurvey}
                                  info={
                                    <InfoIconBenefit
                                      pages={props.pages}
                                      moreInfo="Preventative care is routine health care that includes screenings, check-ups, and patient counseling to prevent illnesses, disease, or other health problems."
                                    />
                                  }
                                  setNumberOfficeVisits={(option) => setPersonalNumberOfficeVisits(option)}
                                  setNumberSpecialtyVisits={(option) => setPersonalNumberSpecialtyVisits(option)}
                                  numberOfficeVisits={personalNumberOfficeVisits}
                                  numberSpecialtyVisits={personalNumberSpecialtyVisits}
                                  type={'personal'}
                                  shouldDisplayDependentQuestions={shouldDisplayDependentQuestions}
                                  dependantNumberOfficeVisits={dependantNumberOfficeVisits}
                                  setDependantNumberOfficeVisits={(option) => setDependantNumberOfficeVisits(option)}
                                  dependantNumberSpecialtyVisits={dependantNumberSpecialtyVisits}
                                  setDependantNumberSpecialtyVisits={(option) =>
                                    setDependantNumberSpecialtyVisits(option)
                                  }
                                  questionDisclaimerText={questionDisclaimerText}
                                  fedexVerbiageChangesFF={fedexVerbiageChangesFF}
                                />
                              </div>
                            );
                          }

                          if (question === 'prescriptions') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolDoctorMedsQuestion
                                  setNumberSpecialtyMeds={(option) => setPersonalNumberSpecialtyMeds(option)}
                                  setNumberPreferredMeds={(option) => setPersonalNumberPreferredMeds(option)}
                                  setNumberBrandMeds={(option) => setPersonalNumberBrandMeds(option)}
                                  setNumberGenericMeds={(option) => setPersonalNumberGenericMeds(option)}
                                  numberGenericMeds={personalNumberGenericMeds}
                                  numberBrandMeds={personalNumberBrandMeds}
                                  numberPreferredMeds={personalNumberPreferredMeds}
                                  numberSpecialtyMeds={personalNumberSpecialtyMeds}
                                  type={'personal'}
                                  shouldDisplayDependentQuestions={shouldDisplayDependentQuestions}
                                  setDependantNumberSpecialtyMeds={(option) => setDependantNumberSpecialtyMeds(option)}
                                  setDependantNumberPreferredMeds={(option) => setDependantNumberPreferredMeds(option)}
                                  setDependantNumberBrandMeds={(option) => setDependantNumberBrandMeds(option)}
                                  setDependantNumberGenericMeds={(option) => setDependantNumberGenericMeds(option)}
                                  dependantNumberGenericMeds={dependantNumberGenericMeds}
                                  dependantNumberBrandMeds={dependantNumberBrandMeds}
                                  dependantNumberPreferredMeds={dependantNumberPreferredMeds}
                                  dependantNumberSpecialtyMeds={dependantNumberSpecialtyMeds}
                                  questionDisclaimerText={questionDisclaimerText}
                                  fedexVerbiageChangesFF={fedexVerbiageChangesFF}
                                />
                              </div>
                            );
                          }

                          if (question === 'emergency_visits') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolUrgentCareQuestion
                                  setUrgentCareVisits={(option) => setPersonalUrgentCareVisits(option)}
                                  setERVisits={(option) => setPersonalERVisits(option)}
                                  numberUrgentVisits={personalUrgentCareVisits}
                                  numberERVisits={personalERVisits}
                                  type={'personal'}
                                  shouldDisplayDependentQuestions={shouldDisplayDependentQuestions}
                                  setDependantUrgentCareVisits={(option) => setDependantUrgentCareVisits(option)}
                                  setDependantERVisits={(option) => setDependantERVisits(option)}
                                  dependantUrgentCareVisits={dependantUrgentCareVisits}
                                  dependantERVisits={dependantERVisits}
                                  questionDisclaimerText={questionDisclaimerText}
                                />
                              </div>
                            );
                          }

                          if (question === 'dental_buyup') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolDentalQuestion
                                  isSurvey={props.isSurvey}
                                  setDental={(option) => setPersonalDental(option)}
                                  dental={personalDental}
                                  type={'personal'}
                                  shouldDisplayDependentQuestions={shouldDisplayDependentQuestions}
                                  setDependantDental={(option) => setDependantDental(option)}
                                  dependantDental={dependantDental}
                                />
                              </div>
                            );
                          }

                          if (question === 'vision_buyup') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolVisionQuestion
                                  isSurvey={props.isSurvey}
                                  setValue={(option) => setPersonalVision(option)}
                                  value={personalVision}
                                  type={'personal'}
                                  shouldDisplayDependentQuestions={shouldDisplayDependentQuestions}
                                  setDependantVision={(option) => setDependantVision(option)}
                                  dependantVision={dependantVision}
                                />
                              </div>
                            );
                          }

                          if (question === 'baby') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolBabyQuestion
                                  isSurvey={props.isSurvey}
                                  info={
                                    <InfoIconBenefit
                                      pages={props.pages}
                                      moreInfo="Only select yes if you will deliver this year."
                                    />
                                  }
                                  setBaby={(option) => setPersonalBaby(option)}
                                  baby={personalBaby}
                                  type={'personal'}
                                  shouldDisplayDependentQuestions={shouldDisplayDependentQuestions}
                                  setDependantBaby={(option) => setDependantBaby(option)}
                                  dependantBaby={dependantBaby}
                                  questionDisclaimerText={questionDisclaimerText}
                                />
                              </div>
                            );
                          }

                          if (question === 'surgeries') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolSurgeryQuestion
                                  isSurvey={props.isSurvey}
                                  info={<InfoIconBenefit pages={props.pages} />}
                                  setPlannedSurgeries={(e) => setPersonalPlannedSurgeries(e.target.value)}
                                  plannedSurgeries={personalPlannedSurgeries}
                                  type={'personal'}
                                  shouldDisplayDependentQuestions={shouldDisplayDependentQuestions}
                                  setDependantPlannedSurgeries={(e) => setDependantPlannedSurgeries(e.target.value)}
                                  dependantPlannedSurgeries={dependantPlannedSurgeries}
                                  questionDisclaimerText={questionDisclaimerText}
                                />
                              </div>
                            );
                          }

                          if (question === 'current_treatments') {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolMedicalConditionsQuestion
                                  setMedicalConditions={(e) => setPersonalMedicalConditions(e.target.value)}
                                  medicalConditions={personalMedicalConditions}
                                  type={'personal'}
                                  shouldDisplayDependentQuestions={shouldDisplayDependentQuestions}
                                  setDependantMedicalConditions={(e) => setDependantMedicalConditions(e.target.value)}
                                  dependantMedicalConditions={dependantMedicalConditions}
                                  questionDisclaimerText={questionDisclaimerText}
                                />
                              </div>
                            );
                          }

                          // TODO: pass in other question types better
                          if (question.DefaultScale) {
                            return (
                              <div
                                ref={elementsRef.current[index]}
                                key={index}
                                className={css`
                                  scroll-margin-top: 20px;
                                `}
                              >
                                <DecisionToolScaleQuestion
                                  enrolling={enrolling}
                                  customEmployeeLabel={props?.displaySettings?.custom_label_employee}
                                  customEmployeeSpouseLabel={props?.displaySettings?.custom_label_employee_spouse}
                                  customFamilyLabel={props?.displaySettings?.custom_label_family}
                                  customEmployeeChildrenLabel={props?.displaySettings?.custom_label_employee_children}
                                  setValue={(value) => {
                                    setRawAnswers({
                                      ...rawAnswers,
                                      [question.AnswerProperty]: value,
                                    });
                                  }}
                                  value={rawAnswers[question.AnswerProperty] || null}
                                  question={question}
                                />
                              </div>
                            );
                          }
                        } else {
                          return null;
                        }
                      })}

                      {currentQuestion === totalNumberOfQuestions &&
                        !!props.customQuestions &&
                        props.customQuestions.length > 0 && (
                          <div
                            ref={customQuestionRef}
                            className={css`
                              scroll-margin-top: 20px;
                            `}
                          >
                            {props.customQuestions.map((customQuestion) => {
                              return (
                                <CustomQuestionsPage
                                  customQuestion={customQuestion}
                                  setAnswer={(responses) => {
                                    let copy = cloneDeep(customQuestionsAnswers);

                                    for (let answer of copy) {
                                      if (answer.questionId === customQuestion.ID) {
                                        answer.selectedResponses = responses;
                                      }
                                    }

                                    setCustomQuestionsAnswers(copy);
                                  }}
                                />
                              );
                            })}
                          </div>
                        )}

                      {!isAcaQuestions && currentQuestion === totalNumberOfQuestions && (
                        <div
                          ref={disclaimerRef}
                          className={css`
                            scroll-margin-top: 20px;
                          `}
                        >
                          <DecisionToolDisclaimer
                            setDisclaimerError={setDisclaimerError}
                            disclaimerError={disclaimerError}
                            setDisclaimerAccepted={setDisclaimerAccepted}
                            disclaimerAccepted={disclaimerAccepted}
                          />
                        </div>
                      )}

                      {props.isDesktop && (
                        <div
                          className={css`
                            margin-bottom: 36px;
                          `}
                        >
                          <Button
                            style={{
                              padding: '12px 42px',
                              backgroundColor: false
                                ? '#D1DAE3'
                                : props.designStyles?.Theme?.Body?.ButtonColor
                                ? props.designStyles?.Theme?.Body?.ButtonColor
                                : '#25282D',
                              color: '#fff',

                              borderRadius: '8px',
                              minWidth: '188px',
                            }}
                            id="footer"
                            variant="contained"
                            role="nav"
                            className="page-nav-next"
                            onClick={handleSubmit}
                            disabled={disableNextQuestionBtn}
                            aria-label="See recommendation results"
                            data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
                            data-ph-capture-attribute-action={posthogAttrs?.actions?.SEE_RESULTS}
                          >
                            {submissionButtonText}
                          </Button>
                        </div>
                      )}
                    </>
                  )}

                  {showRecommendationResults && (
                    <RecommendationViewer
                      setCurrentPageIndex={props.setCurrentPageIndex}
                      restart={() => {
                        setClearLocal(true);
                        props.restartRecommendationFunction();
                      }}
                      setOnRecommendationListPage={props.setOnRecommendationListPage}
                      oldPageIndex={props.oldPageIndex}
                      displaySettings={props.displaySettings}
                      showMonthlyCosts={shouldShowMonthlyCosts()}
                      healthCareRecommendation={recommendation}
                      saveDecisionToolAnswers={getMedicalRec}
                      healthCareRecommendationLoading={recommendationLoading}
                      closeClicked={props.closeClicked}
                      medicalHistory={medicalHistory}
                      selectedBenefits={props.selectedBenefits}
                      setSelectedBenefits={props.setSelectedBenefits}
                      answers={formatAnswers()}
                      setHideButton={setHideButton}
                      hideMedicalPlans={
                        props.currentPage.HTMLContent === 'hideMedicalPlans' ||
                        (!showDynamicQuestion('doctor_visits') &&
                          !showDynamicQuestion('prescriptions') &&
                          !showDynamicQuestion('emergency_visits') &&
                          !showDynamicQuestion('baby') &&
                          !showDynamicQuestion('current_treatments') &&
                          !showDynamicQuestion('surgeries'))
                      }
                      page={props.currentPage}
                      customQuestionsAnswers={customQuestionsAnswers}
                      customQuestions={props.customQuestions}
                      allCustomProducts={allCustomProducts}
                      allCoreProducts={allCoreProducts}
                      spendingAccounts={spendingAccounts}
                      allAdditionalProducts={allAdditionalProducts}
                      recommendedCustomProducts={recommendedCustomProducts}
                      setRecommendedCustomProducts={setRecommendedCustomProducts}
                      showRecommendationResults={showRecommendationResults}
                      setShowRecommendationResults={setShowRecommendationResults}
                      shareClicked={props.shareClicked}
                      recId={props.recId}
                      setRecId={props.setRecId}
                      setOriginalRecs={props.setOriginalRecs}
                      setUserSelectedRecs={props.setUserSelectedRecs}
                      setDecisionToolOpen={props.setDecisionToolOpen}
                      designStyles={props.designStyles}
                    />
                  )}
                </AnimatePresence>
              </div>
            </section>
          </motion.div>
        </main>

        {!props.isDesktop && !recommendationLoading && (
          <div
            className={css`
              height: 80px;
              width: 100%;
              background: white;
              border-top: 1px solid #e8edf3;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 80px;
              gap: 12px;
            `}
          >
            <Button
              style={{
                padding: '12px 12px',
                backgroundColor: props.onRecommendationListPage
                  ? !props?.currentPage?.EnrollmentURL
                    ? props.designStyles?.Theme?.Body?.ButtonColor
                      ? props.designStyles?.Theme?.Body?.ButtonColor
                      : '#25282D'
                    : 'transparent'
                  : props.designStyles?.Theme?.Body?.ButtonColor
                  ? props.designStyles?.Theme?.Body?.ButtonColor
                  : '#25282D',
                color: props.onRecommendationListPage
                  ? props?.currentPage?.EnrollmentURL
                    ? '#25282D'
                    : '#fff'
                  : '#fff',

                marginRight: `${
                  props.onRecommendationListPage ? (props?.currentPage?.EnrollmentURL ? '0px' : '16px') : '16px'
                }`,
                marginLeft: '16px',
                minWidth: '140px',
                width: '100%',
              }}
              id="footer"
              variant={`${
                props.onRecommendationListPage
                  ? props?.currentPage?.EnrollmentURL
                    ? 'outlined'
                    : 'contained'
                  : 'contained'
              }`}
              role="nav"
              className="page-nav-next"
              disabled={disableNextQuestionBtn}
              onClick={handleSubmit}
              data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
              data-ph-capture-attribute-action={posthogAttrs?.actions?.SEE_RESULTS}
            >
              {submissionButtonText}
            </Button>
            {props?.currentPage?.EnrollmentURL && props.onRecommendationListPage && (
              <Button
                style={{
                  padding: '12px 12px',
                  backgroundColor: props.designStyles?.Theme?.Body?.ButtonColor
                    ? props.designStyles?.Theme?.Body?.ButtonColor
                    : '#25282D',
                  color: '#fff',
                  marginRight: '16px',
                  marginLeft: '0px',
                  minWidth: '140px',
                  width: '100%',
                }}
                id="footer"
                variant="contained"
                role="nav"
                className="page-nav-next"
                onClick={() => {
                  props.toggleEnrolledClicked();
                }}
              >
                Enroll Now
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

function convertV1toV2(prod) {
  if (prod.Details) {
    prod = { ...prod, ...prod.Details };
    prod.Carrier = prod.ProviderName;
    prod.CarrierName = prod.ProviderName;
    prod.CarrierID = prod.ProviderID;
    prod.Name = prod.ProductName;

    if (prod.Cost) {
      prod.EmployeeContributionType = prod.Cost?.Contributions?.ContributionType;
      prod.EmployeeContributions = prod.Cost?.Contributions?.MonthlyContributions;
      prod.TotalMonthlyPremiums = prod.Cost?.TotalMonthlyPremiums;
    }
  }
  return prod;
}

export function handleMedicalRecommendations(recommendations, type) {
  for (let rec of recommendations) {
    rec.Type = type;
    if (rec.Plan.Details) {
      rec.Plan = convertV1toV2(rec.Plan);
      rec.Plan.DeductibleResetDate = rec.Plan.Details.DeductibleReset;
      rec.Plan.OptionalPremiumLocation = rec.Plan.Details.PremiumLocation || null;
      rec.Plan.ExternalIdentifier = rec.Plan.Details.ExternalIdentifier || '';
      rec.Plan.NetworkInformation = {
        Name: rec.Plan.ProductNetworkName || rec.Plan.Details.NetworkName,
        Description: rec.Plan.ProductNetworkDescription || rec.Plan.Details.NetworkDescription,
        ProviderSearchURL: rec.Plan.ProductNetworkProviderSearchUrl || rec.Plan.Details.NetworkProviderSearchUrl,
        MultiCarrierNetworkCarrierSearchURL: rec.Plan.Details.MultiCarrierNetworkCarrierSearchUrl,
        MultiCarrierNetworkDescription: rec.Plan.Details.MultiCarrierNetworkDescription,
        MultiCarrierNetworkName: rec.Plan.Details.MultiCarrierNetworkName,
      };

      if (rec.PlanProductSuggestions && rec.PlanProductSuggestions.length > 0) {
        rec.PlanProductSuggestions = rec.PlanProductSuggestions.map((s) => {
          s.Product = convertV1toV2(s.Product);
          return s;
        });
      }
    }
  }

  let plans = [];
  let grouped = groupBy(recommendations, 'Plan.MultiNetworkID');

  for (const key of Object.keys(grouped)) {
    let groupedPlans = grouped[key];
    if (!key || key === 'null') {
      for (let plan of groupedPlans) {
        plan.Plan.buyUpNetwork = false;
        plan.Plan.narrowNetwork = false;
        plans.push(plan);
      }
    } else {
      let groupedPlans = grouped[key];
      let newPlan = {};

      for (let plan of groupedPlans) {
        if (plan.Plan.MultiNetworkCategory === 'core') {
          newPlan = cloneDeep(plan);
        }
      }
      for (let plan of groupedPlans) {
        if (plan.Plan.MultiNetworkCategory === 'buyup') {
          newPlan.Plan.buyUpNetwork = cloneDeep(plan);
        } else if (plan.Plan.MultiNetworkCategory === 'narrow') {
          newPlan.Plan.narrowNetwork = cloneDeep(plan);
        }
      }

      if (!newPlan.Plan.buyUpNetwork) {
        newPlan.Plan.buyUpNetwork = false;
      }

      if (!newPlan.Plan.narrowNetwork) {
        newPlan.Plan.narrowNetwork = false;
      }
      plans.push(newPlan);
    }
  }

  let orderedRec = sortBy(plans, ['-Score']);
  return orderedRec;
}
