import { useStore } from '../../store-provider/use-store';

export const getLink = (link) => {
  let url = link;
  if (!/^https?:\/\//i.test(url) && !/^http?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  try {
    const data = new URL(url);

    // if link is to another Brite guide or the host matches the current host (which can happen if we are white-labeling Brite)
    if (window.location && (data.host === 'britehr.app' || data.host === window.location.host)) {
      // changing the host & protocol to match the window let's us stay in the expected environment, (e.g., if we're running locally)
      data.protocol = window.location.protocol;

      // stay in the current environment if applicable
      if (window.location.host.includes('localhost')) {
        data.host = window.location.host;
      } else if (window.location.host.includes('staging')) {
        data.host = window.location.host;
      }

      data.search = window.location.search; // pass search parameters along so our tracking can be passed between guides
    }
    return data.href;
  } catch (err) {
    return '';
  }
};

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
}

export const useHandleAction = (item) => {
  const {
    data: { pages },
  } = useStore();

  if (!item?.action) return () => {};

  return () => {
    const { type, data = {} } = item?.action;
    if (type === 'open-website' && !!data?.url) {
      const url = getLink(data?.url);
      if (!data?.tabType || data?.tabType === 'new-tab') {
        window.open(url, '_blank');
      } else {
        window.open(url, '_self');
      }
    } else if (type === 'open-email') {
      const body = encodeURIComponent(data.body);
      const subject = encodeURIComponent(data.subject);
      window.location.href = `mailto:${data.sendToEmail}?subject=${subject}&body=${body}`;
    } else if (type === 'open-sms') {
      const device = getMobileOperatingSystem();
      const body = encodeURIComponent(data.message);
      if (device === 'android') {
        window.location.href = `sms:${data.sendToNumber}?body=${body}`;
      } else {
        window.location.href = `sms:${data.sendToNumber}&body=${body}`;
      }
    } else if (type === 'call-phone') {
      window.location.href = `tel:${data.callNumber}`;
    } else if (type === 'route-to-page') {
      const pageIndex = pages.findIndex(({ ID }) => ID === data?.pageId);
      if (!data?.tabType || data?.tabType === 'same-tab') {
        window.open(pageIndex + 1 + window.location.search, '_self');
      } else {
        window.open(pageIndex + 1 + window.location.search, '_blank');
      }
    }
  };
};
