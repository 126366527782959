import { css } from 'emotion';
import { summaryFieldConfigs } from '../../components/brite-viewer/components/benefits/configs/product-summary.config';
import { CostSummary } from '../../components/brite-viewer/components/benefits/cost-summary';
import { FieldComponent } from '../../components/brite-viewer/components/benefits/field-component';
import { Div } from '../div';
import { flex } from '../shared-styles';
import { Text } from '../text';

export const transformFields = (product, list, fieldTransformer) => {
  const data = list.reduce((prev, item) => {
    return { ...prev, [item?.PropertyChain]: item };
  }, {});

  const transformer = typeof fieldTransformer === 'function' ? fieldTransformer({ product, data }) : {};

  let fields = [];
  for (const field of list) {
    const config = field?.PropertyChain in transformer ? transformer[field?.PropertyChain] : {};
    fields.push({ ...field, config });
  }
  return { fields, data };
};

export const getFieldByPropertyChain = (data, propertyChain) => {
  return propertyChain in data ? data[propertyChain] : null;
};

export const recommendationConfigs = {
  vision_buyup: ({ product }) => ({
    Cost: {
      components: [
        () => {
          return (
            <Div className="rec cost">
              <CostSummary data={{ product }} />
              <Text
                bold
                h4
                css={`
                  margin: 16px 32px;
                  margin-bottom: 8px;
                `}
              >
                Plan Details
              </Text>
            </Div>
          );
        },
      ],
    },
  }),

  critical_illness: ({ product }) => {
    return {
      'Details.PreExistingConditions': {
        hideField: true,
      },
    };
  },

  dental_buyup: ({ product }) => ({
    Cost: {
      components: [
        () => {
          return (
            <Div className="rec cost">
              <CostSummary data={{ product }} />
              <Text
                bold
                h4
                css={`
                  margin: 16px 32px;
                  margin-bottom: 8px;
                `}
              >
                Plan Details
              </Text>
            </Div>
          );
        },
      ],
    },
  }),

  hospital_indemnity: () => ({
    'Label.DailyIntensiveCareBenefit': {
      hideField: true,
    },
    'Label.DailyHospitalConfinement': {
      hideField: true,
    },
  }),

  supplimental_life: ({ product }) => summaryFieldConfigs?.supplimental_life({ product }),

  short_term_disability: ({ product, data }) => ({
    'Details.WaitingPeriodIllness': {
      components: [
        ({ field }) => {
          const injuryField = 'Details.WaitingPeriodSickness' in data ? data['Details.WaitingPeriodSickness'] : null;
          return (
            <div
              className={css`
                padding-left: 16px;
                padding-right: 16px;
                display: flex;
              `}
            >
              <Text
                label
                className={css`
                  width: 50%;
                `}
              >
                {field?.RecDisplayValue}
              </Text>
              <Div
                css={css`
                  flex-grow: 1;
                `}
              >
                <FieldComponent fullWidth product={product} field={{ ...field, Post: '/ Illness' }} />
                {injuryField ? (
                  <FieldComponent fullWidth product={product} field={{ ...injuryField, Post: '/ Injury' }} />
                ) : null}
              </Div>
            </div>
          );
        },
      ],
    },
    'Details.WaitingPeriodSickness': {
      hideField: true,
    },
    'Details.BenefitDuration': {
      components: [
        ({ field }) => (
          <div
            className={css`
              padding-left: 16px;
              padding-right: 16px;
              display: flex;
            `}
          >
            <Text
              label
              className={css`
                width: 50%;
              `}
            >
              {field?.RecDisplayValue}
            </Text>
            <Div
              css={css`
                flex-grow: 1;
              `}
            >
              <Div
                css={css`
                  ${flex('right')}
                `}
              >
                <FieldComponent product={product} field={{ ...field }} />
              </Div>
            </Div>
          </div>
        ),
      ],
    },
  }),
};
