import React, { Component, useMemo } from 'react';
import { motion } from 'framer-motion';
import { css } from 'emotion';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Layer, Grommet } from 'grommet';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Bank, Calculator, CreditCard, Money, WarningCircle, X } from 'phosphor-react';
import { Typography } from '@material-ui/core';
import { smallScreen } from '../additional-benefits/product-components/shared-styles';
import { MedicalDetails } from './medical-details';
import {
  getDisplayNumberValue,
  getFieldsObject,
  getProductContributions,
  getProductPremium,
} from '../../brite-viewer/components/benefits/field-utils';
import { Button, Text } from '../../../shared-components';
import { colors } from '../../../shared-components/styles';
import { posthogAttrs } from '../../../posthog-constants';
import { CarrierLogo } from '../../brite-viewer/components/benefits/carrier-logo';
import { flex } from '../../../shared-components/shared-styles';

const networkCard = css`
  cursor: pointer;
  border-radius: 16px;
  background-color: white;
  padding: 24px;
  transition: border 0.1s ease;
  transition: background 0.2s ease;
  border: 1px solid ${colors.gray[300]};
  margin: 24px 32px;
  margin-top: 28px;
  :hover {
    background-color: ${colors.gray[100]};
  }

  ${smallScreen} {
    margin: 24px 12px;
    text-align: left;
    padding: 16px;
  }
`;
export const theme = {
  layer: {
    container: { zIndex: 2000 },
    zIndex: 2000,
  },
};

const selectedNetworkCard = css`
  ${networkCard}
  transition: outline .1s ease;
  outline: 4px solid ${colors.black};
`;

const networkCardCondensed = css`
  cursor: pointer;
  margin-bottom: 8px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: white;
  padding: 12px;
  border: 1px solid #d1dae3;

  ${smallScreen} {
    text-align: left;
  }
`;

const defaultNameMap = {
  narrowNetwork: 'Narrow Network',
  buyUpNetwork: 'Buy Up Network',
};

export const formatCurrency = (value, options) => {
  let formatValue = value || '0';

  formatValue =
    formatValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      ...options,
    }) || '$0.00';

  if (formatValue && formatValue !== 'NaN' && formatValue.slice(-3) === '.00') {
    formatValue = formatValue.slice(0, -3);
  }

  return formatValue;
};

const NetworkCard = ({ plan, type, value, networkView, setNetworkView, showMonthlyCosts, businessId }) => {
  const name = useMemo(() => {
    if (!type) {
      return (plan.Plan && plan.Plan.NetworkInformation && plan.Plan.NetworkInformation.Name) || 'Core Network';
    } else {
      return (
        (plan.Plan &&
          plan.Plan[type] &&
          plan.Plan[type].Plan.NetworkInformation &&
          plan.Plan[type].Plan.NetworkInformation.Name) ||
        defaultNameMap[type]
      );
    }
  }, [type]);

  const listItems = useMemo(() => {
    // is American Health Partners
    if (businessId === 'b779e426-9648-4ce6-8778-addba47f2411') {
      if (type === '') {
        return ['Higher Premiums', 'Lower Discounts', 'More Providers'];
      } else if (type === 'narrowNetwork') {
        return ['Lower Premiums', 'Deeper Discounts', 'Fewer Providers'];
      } else if (type === 'buyUpNetwork') {
        return ['Higher Premium', 'Largest Hospital & Provider Access'];
      }
    } else {
      if (type === '') {
        return ['Moderate Premium', 'Large Network with National Coverage'];
      } else if (type === 'narrowNetwork') {
        return ['Reduced Premium', 'Small Network of High Quality Providers'];
      } else if (type === 'buyUpNetwork') {
        return ['Higher Premium', 'Largest Hospital & Provider Access'];
      }
    }

    return [];
  }, [type]);

  return (
    <div className={networkView === type ? selectedNetworkCard : networkCard} onClick={() => setNetworkView(type)}>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: start;
        `}
      >
        <div>
          <Typography style={{ fontSize: '24px', color: '#25282d' }}>{name}</Typography>
          <ul
            className={css`
              color: #66737f;
              font-size: 14px;
              text-align: left;
              li {
                padding: 4px 0;
              }
              ${smallScreen} {
                display: none;
              }
            `}
          >
            {listItems.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
        <div
          className={css`
            ${smallScreen} {
              margin-top: 8px;
            }
          `}
        >
          <div
            className={css`
              color: #25282d;
              font-size: 24px;
              text-align: right;
              line-height: 1.5;
              ${smallScreen} {
                font-size: 14px;
              }
            `}
          >
            {value}
          </div>
          <div
            className={css`
              color: #25282d;
              font-size: 14px;
              text-align: right;
              line-height: 1.5;
              ${smallScreen} {
                font-size: 12px;
              }
            `}
          >
            Total estimated cost{' '}
            <Tooltip
              PopperProps={{ style: { zIndex: 200000 } }}
              placement="right"
              title="Includes the monthly insurance premium plus the estimated cost of medical services."
            >
              <HelpOutlineIcon
                style={{
                  fontSize: 18,
                  top: '3px',
                  marginLeft: '2px',
                  position: 'relative',
                }}
              />
            </Tooltip>
          </div>

          <Text
            className={css`
              text-align: right;
              ${smallScreen} {
                font-size: 8px;
              }
            `}
          >
            {showMonthlyCosts ? '(monthly average)' : '(annual)'}
          </Text>
        </div>
      </div>
    </div>
  );
};

export class PlanViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learnMoreOpen: false,
      meaningsOpen: false,
      networkView: '',
    };
  }

  toggleSurestAddOns = () => {
    this.setState((prevState, props) => {
      return {
        surestAdditionalAddOnCostsModalOpen: !prevState.surestAdditionalAddOnCostsModalOpen,
      };
    });
  };

  toggleLearnMore = () => {
    this.setState((prevState, props) => {
      return {
        learnMoreOpen: !prevState.learnMoreOpen,
      };
    });
  };

  toggleMeanings = () => {
    this.setState((prevState, props) => {
      return {
        meaningsOpen: !prevState.meaningsOpen,
      };
    });
  };

  getMonthsRemaining = (rec) => {
    return rec?.PlanCostsForYear?.MonthsIncluded;
  };

  getTotalCombinedCost = (rec) => {
    if (rec.Plan.ID === 'none') {
      return 0;
    }

    const showMonthlyCosts = this?.props?.showMonthlyCosts;
    const monthsRemaining = rec?.PlanCostsForYear?.MonthsIncluded;
    const cost = rec.PlanCostsForYear.FinalPlanCost;

    if (cost < 0) {
      return `$${getDisplayNumberValue(0, { decimals: 2, round: this.props.round })}`;
    }

    if (showMonthlyCosts) {
      return `$${getDisplayNumberValue(cost / monthsRemaining, {
        decimals: 2,
        round: this.props.round,
      })}`;
    } else {
      return `$${getDisplayNumberValue(cost, { decimals: 2, round: this.props.round })}`;
    }
  };

  getMonthlyPlanCostForEmployeeNotFixed = (rec) => {
    console.log(rec);
    const { RelavantTier: tier } = rec;
    const totalMonthlyPremium = rec.Plan.EmployeeContributions?.[tier];
    const employeeContribution = rec.Plan.TotalMonthlyPremiums?.[tier];
    if (rec.Plan.EmployeeContributionType === '%') {
      const percentage = employeeContribution !== 0 ? employeeContribution / 100.0 : 0;
      return totalMonthlyPremium - totalMonthlyPremium * percentage;
    } else {
      return totalMonthlyPremium - employeeContribution;
    }
  };

  getMonthlyPlanCostForEmployee = (rec) => {
    if (rec.Plan.ID === 'none') {
      return 0;
    }

    let monthly = this.getMonthlyPlanCostForEmployeeNotFixed(rec);

    if (this.props.displaySettings.premiumInterval !== 12) {
      let yearly = monthly * 12.0;
      let impactOnPaycheck = yearly / this.props.displaySettings.premiumInterval;
      return Math.round(impactOnPaycheck);
    }

    // round to nearest dollar because that's good enough
    return Math.round(monthly);
  };

  getHSAValue = (rec) => {
    if (!rec.Plan.HSACompatible) {
      return 'No account';
    } else if (rec.Plan.HSAContributionType === 'FSA') {
      return 'FSA Available';
    } else if (
      rec.Plan.HSAContributionType === 'Employer Match' ||
      rec.Plan.HSAContributionType === 'HSA - Employer Match'
    ) {
      const tier = rec?.RelevantTier;
      const contributions = rec.Plan.HSAContributions;
      return (
        <span>
          ${contributions?.[`${tier}EmployerMonthlyMatch`]?.toFixed(2)} for every $1 up to $
          {contributions?.[`${tier}EmployerMonthlyMatchMax`]?.toFixed(2)}
        </span>
      );
    } else if (rec.Plan.HSAContributionType === 'HRA') {
      // unfortunately we're using the same field for HRA and HSA, which is very confusing because one uses monthly values and one uses yearly values.
      // also, this really should be taking into account the employee's plan usage, because the HRA is only a tangible benefit if you have medical costs (unlike the HSA)
      // we can address this in the DT revamp.
      const tier = rec?.RelevantTier;
      const annualValue = rec.Plan.HSAContributions?.[tier];
      if (!annualValue) {
        // this shouldn't happen
        return '';
      }
      const monthlyValue = annualValue / 12;
      return `$${monthlyValue.toFixed(2)}`;
    } else {
      const tier = rec?.RelevantTier;
      return `$${rec.Plan.HSAContributions?.[tier]?.toFixed(2)}`;
    }
  };

  isSurgerySelected = () => {
    if (
      this.props.answers.EmployeeEnrolling.Surgeries.Minor ||
      this.props.answers.EmployeeEnrolling.Surgeries.MinorInPatient ||
      this.props.answers.EmployeeEnrolling.Surgeries.MinorOutPatient ||
      this.props.answers.EmployeeEnrolling.Surgeries.MajorInPatient ||
      this.props.answers.DependentEnrolling.Surgeries.Minor ||
      this.props.answers.DependentEnrolling.Surgeries.MinorInPatient ||
      this.props.answers.DependentEnrolling.Surgeries.MinorOutPatient ||
      this.props.answers.DependentEnrolling.Surgeries.MajorInPatient
    ) {
      return true;
    }
    return false;
  };

  showSurestAddOnCosts = (rec) => {
    if (
      rec?.Plan?.PlanType?.toLowerCase().includes('surest') &&
      this.isSurgerySelected() &&
      this.props.businessId !== 'ddf03fa5-38c8-4559-80fd-abe893c6c706'
    ) {
      return true;
    }
    return false;
  };

  hasLogo = () => {
    if (this.props.plan && this.props.plan.Business && this.props.plan.Business.LogoURL) {
      return true;
    }
    return false;
  };

  getLogo = () => {
    if (this.props.plan && this.props.plan.Business && this.props.plan.Business.LogoURL) {
      return this.props.plan.Business.LogoURL;
    }
    return '';
  };

  hasBuyupOrNarrowNetwork = () => {
    if (this.props.plan.Plan.narrowNetwork || this.props.plan.Plan.buyUpNetwork) {
      return true;
    } else {
      return false;
    }
  };

  hasNarrowNetwork = () => {
    if (this.props.plan.Plan.narrowNetwork) {
      return true;
    } else {
      return false;
    }
  };

  hasBuyupNetwork = () => {
    if (this.props.plan.Plan.buyUpNetwork) {
      return true;
    } else {
      return false;
    }
  };

  multiCarrierPlanApplies = () => {
    if (
      this.props.plan?.Plan?.MultiCarrierApplicableStates?.includes(this.props.answers.State) ||
      this.props.plan?.Plan?.MultiCarrierApplicableStates?.includes('ALL') ||
      this.props.plan?.Plan?.MultiCarrierApplicableStates?.includes('{}') ||
      this.props.plan?.Plan?.MultiCarrierApplicableStates?.length === 0
    ) {
      return true;
    }
  };

  normalCarrierPlanApplies = () => {
    if (!this.props.answers.State) {
      return true;
    }

    if (
      this.props.plan?.Plan?.ApplicableStates?.includes(this.props.answers.State) ||
      this.props.plan?.Plan?.ApplicableStates?.includes('ALL') ||
      this.props.plan?.Plan?.ApplicableStates?.includes('{}') ||
      this.props.plan?.Plan?.ApplicableStates?.length === 0
    ) {
      return true;
    }
  };

  isMultiCarrier = () => {
    if (this.props.plan.Plan.IsMultiCarrier && this.normalCarrierPlanApplies() && this.multiCarrierPlanApplies()) {
      return true;
    }

    return false;
  };

  premiumTerm = () => (this.props.fedexVerbiageChangesFFValue ? 'Employee Contribution' : 'Premium');
  hraReimbursementDescription = () => (this.props.fedexVerbiageChangesFFValue ? 'Credits' : 'Reimbursement');
  premiumDescription = () => {
    const premiumAdj = this.props.fedexVerbiageChangesFFValue ? 'Average' : 'Your';

    return this.props.displaySettings.premiumInterval === 12
      ? `${premiumAdj} Monthly ${this.premiumTerm()}`
      : `${premiumAdj} ${this.premiumTerm()} per Paycheck`;
  };

  render() {
    const isCardinalHealth = this.props.businessId === 'ddf03fa5-38c8-4559-80fd-abe893c6c706';
    const cardinalTelehealthOverride = isCardinalHealth ? 'Virtual Visit' : 'Telehealth Visit';
    let selectedRecommendation = this.props.plan;
    const networkPlan =
      this.state.networkView === '' ? selectedRecommendation : selectedRecommendation.Plan[this.state.networkView];
    const layout = this.props.productLayouts?.[networkPlan?.Plan?.ID] || {};
    const fieldsObject = getFieldsObject(layout?.Layout?.Sections);
    const relevantTier = selectedRecommendation?.RelevantTier;
    const costExplanation = `Includes the monthly ${this.premiumTerm().toLowerCase()} plus the estimated cost of medical services, minus the max employer contribution to any eligible tax-advantaged accounts, such as your HSA.`;

    return (
      <motion.div
        key={'planvieweruew_question'}
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            x: 400,
            opacity: 0,
          },
          pageAnimate: {
            x: 0,
            opacity: 1,
          },
          pageExit: {
            x: -400,
            opacity: 0,
          },
        }}
        className={css`
          position: relative;
        `}
      >
        {this.isMultiCarrier() && (
          <>
            <div
              className={css`
                display: flex;

                padding: 32px;
                justify-content: space-between;
                align-items: center;
                ${smallScreen} {
                  padding: 24px;
                }
              `}
            >
              <div
                className={css`
                  font-weight: bold;
                  text-align: center;
                  font-size: 32px;
                  line-height: 133.4%;
                  color: #25282d;

                  @media only screen and (max-width: 595px) {
                    font-size: 16px;
                  }
                `}
              >
                {this.props.plan.Plan.Name || 'Multiple Carrier Plan'}
              </div>

              {!this.props.hideEstimatedCost ? (
                <div
                  className={css`
                    flex-grow: 1;
                  `}
                >
                  <div
                    className={css`
                      color: #25282d;
                      font-size: 24px;
                      text-align: right;
                      line-height: 1.5;
                      ${smallScreen} {
                        font-size: 14px;
                      }
                    `}
                  >
                    {this.getTotalCombinedCost(selectedRecommendation)}
                  </div>
                  <Text
                    label
                    bold
                    className={css`
                      text-align: right;
                      padding-top: 8px;
                      ${smallScreen} {
                        font-size: 10px;
                      }
                    `}
                  >
                    <Tooltip PopperProps={{ style: { zIndex: 200000 } }} placement="right" title={costExplanation}>
                      <HelpOutlineIcon
                        style={{
                          fontSize: 18,
                          top: '3px',
                          marginLeft: '2px',
                          position: 'relative',
                        }}
                      />
                    </Tooltip>
                    Estimated net costs{' '}
                  </Text>

                  <Text
                    className={css`
                      text-align: right;
                      ${smallScreen} {
                        font-size: 8px;
                      }
                    `}
                  >
                    {this.props.showMonthlyCosts ? '(monthly average)' : '(annual)'}
                  </Text>
                </div>
              ) : fieldsObject?.Cost?.State !== 'hide' ? (
                <div
                  className={css`
                    width: 50%;
                    ${smallScreen} {
                      width: 100%;
                      padding-bottom: 16px;
                    }
                  `}
                >
                  <div
                    className={css`
                      display: flex;
                    `}
                  >
                    <CreditCard />
                    <div
                      className={css`
                        font-weight: bold;
                        color: #5a5a5a;
                        font-size: 24px;
                        padding: 8px;
                      `}
                    >
                      {getProductPremium({
                        cost: selectedRecommendation?.Plan?.Cost,
                        key: relevantTier,
                        interval: this.props.displaySettings.premiumInterval,
                      })}
                    </div>
                  </div>
                  <div
                    className={css`
                      font-size: 18px;
                      margin-bottom: 5px;
                      line-height: 18px;
                      margin-top: 5px;
                      color: #66737f;
                    `}
                  >
                    {this.premiumDescription()}
                  </div>
                </div>
              ) : null}
            </div>

            <div
              className={css`
                padding-bottom: 16px;
              `}
            >
              <div
                className={css`
                  background: #f5f7fa;
                  font-size: 18px;
                  color: #66737f;
                  padding: 10px;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  line-height: 26px;
                  margin-left: 16px;
                  margin-right: 16px;

                  @media only screen and (max-width: 595px) {
                    font-size: 14px;
                    line-height: 160%;
                  }
                `}
              >
                <WarningCircle
                  size={24}
                  className={css`
                    margin-right: 16px;
                  `}
                />
                <div
                  className={css`
                    @media only screen and (max-width: 595px) {
                      font-size: 10px;
                      line-height: 14px;
                    }
                  `}
                >
                  This plan includes multiple networks.{' '}
                  <span
                    className={css`
                      color: #25282d;
                    `}
                  >
                    Select the one that works for you!
                  </span>
                </div>
              </div>
            </div>

            <div
              className={css`
                border-radius: 16px;
                border: 1px solid #d1dae3;
                padding: 32px;
                margin: 32px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap-reverse;
                  gap: 12px;
                  @media (max-width: 500px) {
                    justify-content: center;
                  }
                `}
              >
                <div>
                  <Typography style={{ fontSize: '24px', color: '#25282d' }}>
                    {this.props.plan.Plan.MultiCarrierName || 'Carrier 2'}
                  </Typography>

                  {this.props.plan.Plan.NetworkInformation.MultiCarrierNetworkCarrierSearchURL && (
                    <div
                      className={css`
                        margin-top: 12px;
                      `}
                    >
                      <Button
                        secondary
                        onClick={() => {
                          window.open(
                            this.props.plan.Plan.NetworkInformation.MultiCarrierNetworkCarrierSearchURL,
                            '_blank'
                          );
                        }}
                        data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
                        data-ph-capture-attribute-action={posthogAttrs?.actions?.SEARCH_NETWORK}
                      >
                        Search Network
                      </Button>
                    </div>
                  )}
                </div>
                {this.props.plan.Plan.MultiCarrierLogoUrl && (
                  <div
                    className={css`
                      border-radius: 8px;
                      border: 1px solid #d1dae3;
                      padding: 12px;
                    `}
                  >
                    <img
                      src={this.props.plan.Plan.MultiCarrierLogoUrl}
                      alt="Carrier Logo"
                      className={css`
                        max-height: 60px;
                        max-width: 160px;
                        ${smallScreen} {
                          max-height: 60px;
                          max-width: 125px;
                        }
                      `}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className={css`
                border-radius: 16px;
                border: 1px solid #d1dae3;
                padding: 32px;
                margin: 32px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap-reverse;
                  gap: 12px;
                  @media (max-width: 500px) {
                    justify-content: center;
                  }
                `}
              >
                <div>
                  <Typography style={{ fontSize: '24px', color: '#25282d' }}>
                    {this.props.plan.Plan.ProviderName || 'Carrier 1'}
                  </Typography>
                  {this.props.plan.Plan.NetworkInformation.ProviderSearchURL && (
                    <div
                      className={css`
                        margin-top: 12px;
                      `}
                    >
                      <Button
                        secondary
                        onClick={() => {
                          window.open(this.props.plan.Plan.NetworkInformation.ProviderSearchURL, '_blank');
                        }}
                        data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
                        data-ph-capture-attribute-action={posthogAttrs?.actions?.SEARCH_NETWORK}
                      >
                        Search Network
                      </Button>
                    </div>
                  )}
                </div>
                {this.hasLogo() && (
                  <div
                    className={css`
                      border-radius: 8px;
                      border: 1px solid #d1dae3;
                      padding: 12px;
                    `}
                  >
                    <img
                      src={this.getLogo()}
                      alt="Carrier Logo"
                      className={css`
                        max-height: 60px;
                        max-width: 160px;
                        ${smallScreen} {
                          max-height: 60px;
                          max-width: 125px;
                        }
                      `}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {!this.hasBuyupOrNarrowNetwork() && !this.isMultiCarrier() && (
          <div
            className={css`
              display: flex;

              padding: 32px;
              padding-bottom: 16px;
              justify-content: space-between;
              ${this.hasLogo() ? 'align-items: center' : 'align-items: start'};
              ${smallScreen} {
                padding: 16px;
              }
            `}
          >
            <div
              className={css`
                flex-grow: 1;
                min-width: 66%;
              `}
            >
              {this.hasLogo() && this.normalCarrierPlanApplies() && (
                <div
                  className={css`
                    margin-bottom: 12px;
                    ${smallScreen} {
                      margin-bottom: 4px;
                    }
                  `}
                >
                  <img
                    src={this.getLogo()}
                    alt="Carrier Logo"
                    className={css`
                      max-height: 60px;
                      max-width: 200px;
                      ${smallScreen} {
                        max-height: 60px;
                        max-width: 125px;
                      }
                    `}
                  />
                </div>
              )}
              {!this.normalCarrierPlanApplies() && this.props.plan.Plan.MultiCarrierLogoUrl && (
                <div
                  className={css`
                    margin-bottom: 12px;
                    ${smallScreen} {
                      margin-bottom: 4px;
                    }
                  `}
                >
                  <img
                    src={this.props.plan.Plan.MultiCarrierLogoUrl}
                    alt="Carrier Logo"
                    className={css`
                      max-height: 60px;
                      max-width: 200px;
                      ${smallScreen} {
                        max-height: 60px;
                        max-width: 125px;
                      }
                    `}
                  />
                </div>
              )}
              <Text
                h2
                className={css`
                  @media only screen and (max-width: 595px) {
                    font-size: 16px;
                  }
                `}
              >
                {this.props.plan.Plan.Name}
              </Text>
            </div>

            {!this.props.hideEstimatedCost ? (
              <div
                className={css`
                  flex-grow: 1;
                `}
              >
                <div
                  className={css`
                    color: #25282d;
                    font-size: 24px;
                    text-align: right;
                    line-height: 1.5;
                    ${smallScreen} {
                      font-size: 14px;
                    }
                  `}
                >
                  {this.getTotalCombinedCost(selectedRecommendation)}
                </div>
                <Text
                  label
                  bold
                  className={css`
                    text-align: right;
                    padding-top: 8px;
                    ${smallScreen} {
                      font-size: 10px;
                    }
                  `}
                >
                  <Tooltip PopperProps={{ style: { zIndex: 200000 } }} placement="right" title={costExplanation}>
                    <HelpOutlineIcon
                      style={{
                        fontSize: 18,
                        top: '3px',
                        marginRight: '2px',
                        position: 'relative',
                      }}
                    />
                  </Tooltip>
                  Estimated net costs{' '}
                </Text>

                <Text
                  className={css`
                    text-align: right;
                    ${smallScreen} {
                      font-size: 8px;
                      line-height: 8px;
                    }
                  `}
                >
                  {this.props.showMonthlyCosts ? '(monthly average)' : '(annual)'}
                </Text>
              </div>
            ) : (
              <div
                className={css`
                  flex-grow: 1;
                `}
              >
                <div
                  className={css`
                    color: #25282d;
                    font-size: 24px;
                    text-align: right;
                    line-height: 1.5;
                    ${smallScreen} {
                      font-size: 14px;
                    }
                  `}
                >
                  {getProductPremium({
                    cost: selectedRecommendation?.Plan?.Cost,
                    key: relevantTier,
                    interval: this.props.displaySettings.premiumInterval,
                  })}
                </div>
                <Text
                  label
                  className={css`
                    text-align: right;
                    padding-top: 8px;
                    font-size: 12px;
                    line-height: 12px;
                  `}
                >
                  {this.premiumDescription()}
                </Text>
              </div>
            )}
          </div>
        )}

        {this.hasBuyupOrNarrowNetwork() && !this.isMultiCarrier() && (
          <>
            <div
              className={css`
                display: flex;

                padding: 32px;
                justify-content: space-between;
                align-items: center;
                ${smallScreen} {
                  padding: 24px;
                }
              `}
            >
              <Text
                h2
                className={css`
                  @media only screen and (max-width: 595px) {
                    font-size: 16px;
                  }
                `}
              >
                {this.props.plan.Plan.Name}
              </Text>

              {this.hasLogo() && (
                <div>
                  <img
                    src={this.getLogo()}
                    alt="Carrier Logo"
                    className={css`
                      max-height: 40px;
                      max-width: 100px;
                    `}
                  />
                </div>
              )}
            </div>

            <div
              className={css`
                padding-bottom: 8px;
              `}
            >
              <div
                className={css`
                  background: #f5f7fa;
                  font-size: 18px;
                  color: #66737f;
                  padding: 10px;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  line-height: 26px;
                  margin-left: 16px;
                  margin-right: 16px;

                  @media only screen and (max-width: 595px) {
                    font-size: 14px;
                    line-height: 160%;
                  }
                `}
              >
                <WarningCircle
                  size={24}
                  className={css`
                    margin-right: 16px;
                  `}
                />
                <div
                  className={css`
                    @media only screen and (max-width: 595px) {
                      font-size: 10px;
                      line-height: 14px;
                    }
                  `}
                >
                  This plan includes multiple networks.{' '}
                  <span
                    className={css`
                      color: #25282d;
                    `}
                  >
                    Select the one that works for you!
                  </span>
                </div>
              </div>

              {this.hasNarrowNetwork() && (
                <NetworkCard
                  businessId={this.props.businessId}
                  plan={selectedRecommendation}
                  networkView={this.state.networkView}
                  setNetworkView={(type) => this.setState({ ...this.state, networkView: type })}
                  type="narrowNetwork"
                  value={this.getTotalCombinedCost(selectedRecommendation.Plan.narrowNetwork)}
                  showMonthlyCosts={this.props.showMonthlyCosts}
                />
              )}

              <NetworkCard
                businessId={this.props.businessId}
                plan={selectedRecommendation}
                networkView={this.state.networkView}
                setNetworkView={(type) => this.setState({ ...this.state, networkView: type })}
                type=""
                value={this.getTotalCombinedCost(selectedRecommendation)}
                showMonthlyCosts={this.props.showMonthlyCosts}
              />

              {this.hasBuyupNetwork() && (
                <NetworkCard
                  businessId={this.props.businessId}
                  plan={selectedRecommendation}
                  networkView={this.state.networkView}
                  setNetworkView={(type) => this.setState({ ...this.state, networkView: type })}
                  type="buyUpNetwork"
                  value={this.getTotalCombinedCost(selectedRecommendation.Plan.buyUpNetwork)}
                  showMonthlyCosts={this.props.showMonthlyCosts}
                />
              )}
            </div>
          </>
        )}

        <div
          className={css`
            ${flex('left')}
            padding: 24px 8px;
            padding-top: 0;
          `}
        >
          <Button
            naked
            css={`
              text-transform: none;
              text-decoration: underline;
            `}
            onClick={() => this.toggleLearnMore()}
            data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
            data-ph-capture-attribute-action={posthogAttrs?.actions?.VIEW_MORE}
            data-ph-capture-attribute-product-id={selectedRecommendation.Plan?.ID}
            data-ph-capture-attribute-product-type={selectedRecommendation.Plan?.Type}
          >
            Learn More
          </Button>
        </div>

        {this.state.learnMoreOpen && (
          <Grommet theme={theme}>
            <Layer
              responsive={true}
              position="center"
              modal
              margin="medium"
              onClickOutside={() => this.toggleLearnMore()}
              onEsc={() => this.toggleLearnMore()}
              style={{ borderRadius: '16px' }}
              className={css`
                font-family: Roboto, Montserrat, sans-serif !important;
                overflow: hidden;
              `}
            >
              <div
                className={css`
                  border-radius: 16px;
                `}
              >
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid ${colors.gray[300]};
                    padding: 32px;
                  `}
                >
                  <Text h1>{this.props.plan.Plan.Name}</Text>
                  <IconButton onClick={() => this.toggleLearnMore()}>
                    <X />
                  </IconButton>
                </div>
                <div
                  className={css`
                    max-height: 50vh;
                    width: 100%;
                    ${smallScreen} {
                      max-height: 100vh;
                    }
                    overflow: auto;
                  `}
                >
                  <div
                    className={css`
                      width: 100%;
                      margin-top: 32px;
                      margin-bottom: 160px;
                    `}
                  >
                    {this.hasBuyupOrNarrowNetwork() && (
                      <>
                        <div
                          className={css`
                            background: #f5f7fa;
                            font-size: 18px;
                            color: #66737f;
                            padding: 10px;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 18px;
                            line-height: 26px;
                            margin-bottom: 24px;

                            @media only screen and (max-width: 595px) {
                              font-size: 14px;
                              line-height: 160%;
                            }
                          `}
                        >
                          <WarningCircle
                            size={24}
                            className={css`
                              margin-right: 16px;
                            `}
                          />
                          <div>
                            This plan includes multiple networks.{' '}
                            <span
                              className={css`
                                color: #25282d;
                              `}
                            >
                              Select the one that works for you!
                            </span>
                          </div>
                        </div>

                        {this.hasNarrowNetwork() && (
                          <NetworkCard
                            businessId={this.props.businessId}
                            plan={selectedRecommendation}
                            networkView={this.state.networkView}
                            setNetworkView={(type) =>
                              this.setState({
                                ...this.state,
                                networkView: type,
                              })
                            }
                            type="narrowNetwork"
                            value={this.getTotalCombinedCost(selectedRecommendation.Plan.narrowNetwork)}
                            showMonthlyCosts={this.props.showMonthlyCosts}
                          />
                        )}

                        <NetworkCard
                          businessId={this.props.businessId}
                          plan={selectedRecommendation}
                          networkView={this.state.networkView}
                          setNetworkView={(type) => this.setState({ ...this.state, networkView: type })}
                          type=""
                          value={this.getTotalCombinedCost(selectedRecommendation)}
                          showMonthlyCosts={this.props.showMonthlyCosts}
                        />

                        {this.hasBuyupNetwork() && (
                          <NetworkCard
                            businessId={this.props.businessId}
                            plan={selectedRecommendation}
                            networkView={this.state.networkView}
                            setNetworkView={(type) =>
                              this.setState({
                                ...this.state,
                                networkView: type,
                              })
                            }
                            type="buyUpNetwork"
                            value={this.getTotalCombinedCost(selectedRecommendation.Plan.buyUpNetwork)}
                            showMonthlyCosts={this.props.showMonthlyCosts}
                          />
                        )}
                      </>
                    )}

                    <div
                      className={css`
                        text-align: left;
                      `}
                    >
                      <div
                        className={css`
                          margin: 32px;
                          margin-top: 45px;
                          margin-bottom: 30px;
                        `}
                      >
                        <div
                          className={css`
                            display: flex;
                            ${smallScreen} {
                              display: block;
                            }
                          `}
                        >
                          {fieldsObject?.Cost?.State !== 'hide' ? (
                            <div
                              className={css`
                                width: 50%;
                                ${smallScreen} {
                                  width: 100%;
                                  padding-bottom: 16px;
                                }
                              `}
                            >
                              <div
                                className={css`
                                  display: flex;
                                `}
                              >
                                <CreditCard />
                                <div
                                  className={css`
                                    font-weight: bold;
                                    color: #5a5a5a;
                                    font-size: 24px;
                                    padding: 8px;
                                  `}
                                >
                                  {getProductPremium({
                                    cost: networkPlan?.Plan?.Cost,
                                    key: relevantTier,
                                    interval: this.props.displaySettings.premiumInterval,
                                  })}
                                </div>
                              </div>
                              <div
                                className={css`
                                  font-size: 18px;
                                  margin-bottom: 5px;
                                  line-height: 18px;
                                  margin-top: 5px;
                                  color: #66737f;
                                `}
                              >
                                {this.premiumDescription()}
                              </div>

                              {this.props.fedexVerbiageChangesFFValue && (
                                <div
                                  className={css`
                                    font-size: 12px;
                                    margin-bottom: 5px;
                                    line-height: 12px;
                                    font-style: italic;
                                    color: ${colors.gray[500]};
                                  `}
                                >
                                  Refer to your specific information for your contributions.
                                </div>
                              )}
                            </div>
                          ) : null}

                          {!layout.HideEmployerPremiumContributions && (
                            <div
                              className={css`
                                width: 50%;
                                ${smallScreen} {
                                  width: 100%;
                                  padding-bottom: 16px;
                                }
                              `}
                            >
                              <div
                                className={css`
                                  display: flex;
                                `}
                              >
                                <Money />
                                <div
                                  className={css`
                                    font-weight: bold;
                                    color: #5a5a5a;
                                    font-size: 24px;
                                    padding: 8px;
                                  `}
                                >
                                  {getProductContributions({
                                    cost: networkPlan?.Plan?.Cost,
                                    key: relevantTier,
                                    interval: this.props.displaySettings.premiumInterval,
                                  })}
                                </div>
                              </div>
                              <div
                                className={css`
                                  font-size: 18px;
                                  margin-bottom: 5px;
                                  line-height: 18px;
                                  margin-top: 5px;
                                  color: #66737f;
                                `}
                              >
                                {this.props.displaySettings.premiumInterval === 12
                                  ? `Employer Contributions per Month`
                                  : `Employer Contributions per Paycheck`}
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          className={css`
                            display: flex;
                            margin-top: 40px;
                            margin-bottom: 50px;
                            ${smallScreen} {
                              display: block;
                              margin-top: 0;
                            }
                          `}
                        >
                          <div
                            className={css`
                              width: 50%;
                              ${smallScreen} {
                                width: 100%;
                                padding-bottom: 16px;
                              }
                            `}
                          >
                            <div
                              className={css`
                                display: flex;
                                align-items: center;
                              `}
                            >
                              <Bank />
                              <div
                                className={css`
                                  font-weight: bold;
                                  color: #5a5a5a;
                                  font-size: 24px;
                                  padding: 8px;
                                  ${smallScreen} {
                                    width: 100%;
                                  }
                                `}
                              >
                                {this.getHSAValue(networkPlan)}
                              </div>
                            </div>

                            {this.getHSAValue(networkPlan) !== 'No account' &&
                              networkPlan.Plan.HSAContributionType !== 'FSA' && (
                                <div
                                  className={css`
                                    font-size: 18px;
                                    margin-bottom: 5px;
                                    line-height: 18px;
                                    margin-top: 5px;
                                    color: #66737f;
                                    padding-right: 5px;
                                  `}
                                >
                                  {this.props.fedexVerbiageChangesFFValue && 'Estimated '}
                                  Monthly Employer{' '}
                                  {networkPlan.Plan.HSAContributionType === 'HRA'
                                    ? 'HRA'
                                    : networkPlan.Plan.HSAContributionType === 'FSA'
                                    ? 'FSA'
                                    : 'HSA'}{' '}
                                  {networkPlan.Plan.HSAContributionType === 'HRA'
                                    ? this.hraReimbursementDescription()
                                    : 'Contributions'}{' '}
                                  {networkPlan.Plan.HSAContributionType === 'Employer Match' ||
                                  networkPlan.Plan.HSAContributionType === 'HSA - Employer Match'
                                    ? '(match)'
                                    : ''}
                                </div>
                              )}
                          </div>
                          <div
                            className={css`
                              width: 50%;
                              ${smallScreen} {
                                width: 100%;
                                padding-bottom: 16px;
                              }
                            `}
                          >
                            <div
                              className={css`
                                display: flex;
                              `}
                            >
                              <Calculator />
                              <div
                                className={css`
                                  font-weight: bold;
                                  color: #5a5a5a;
                                  font-size: 24px;
                                  padding: 8px;
                                  flex: 1;
                                  text-align: left;
                                `}
                              >
                                {this.getTotalCombinedCost(networkPlan)}
                              </div>
                            </div>
                            <div
                              className={css`
                                font-size: 18px;
                                margin-bottom: 5px;
                                line-height: 18px;
                                margin-top: 5px;
                                color: #66737f;
                                text-align: left;
                              `}
                            >
                              *Your Estimated Net Cost
                              <Tooltip
                                PopperProps={{ style: { zIndex: 200000 } }}
                                placement="right"
                                title={costExplanation}
                              >
                                <HelpOutlineIcon
                                  style={{
                                    fontSize: 18,
                                    top: '3px',
                                    marginLeft: '2px',
                                    position: 'relative',
                                  }}
                                />
                              </Tooltip>
                              <Text
                                className={css`
                                  text-align: left;
                                  ${smallScreen} {
                                    font-size: 8px;
                                  }
                                `}
                              >
                                {this.props.showMonthlyCosts
                                  ? '(monthly average)'
                                  : this.getMonthsRemaining(selectedRecommendation)
                                  ? `(for ${this.getMonthsRemaining(selectedRecommendation)} months)`
                                  : null}
                              </Text>
                            </div>
                          </div>
                        </div>
                        {networkPlan.Plan.NetworkInformation &&
                          networkPlan.Plan.NetworkInformation.Description &&
                          !networkPlan.Plan.IsMultiCarrier && (
                            <div
                              className={css`
                                font-size: 18px;
                                margin-bottom: 5px;
                                line-height: 18px;
                                margin-top: 5px;
                                color: #66737f;
                              `}
                            >
                              {networkPlan.Plan.NetworkInformation.Description}
                            </div>
                          )}
                      </div>

                      {this.showSurestAddOnCosts(selectedRecommendation) && (
                        <div>
                          <div
                            className={css`
                              text-align: center;
                              font-size: 12px;
                              margin-top: 12px;
                              margin-bottom: 12px;
                            `}
                          >
                            *there may be additional costs associated with some planned procedures. Click below to
                            search for procedures that could impact your expected costs.
                          </div>
                          <div
                            className={css`
                              display: flex;
                              justify-content: center;
                              margin-top: 16px;
                              margin-bottom: 16px;
                              padding-bottom: 25px;
                            `}
                          >
                            <Button
                              secondary
                              onClick={() => {
                                this.toggleSurestAddOns();
                              }}
                              data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
                              data-ph-capture-attribute-action={posthogAttrs?.actions?.SUREST_TOGGLE}
                            >
                              {this.state.surestAdditionalAddOnCostsModalOpen
                                ? 'Close flexible benefits'
                                : 'Click to see flexible benefits'}
                            </Button>
                          </div>

                          {this.state.surestAdditionalAddOnCostsModalOpen && (
                            <div
                              className={css`
                                border: 1px solid #e0e0e0;
                                padding: 16px;
                                border-radius: 12px;
                                margin-bottom: 16px;
                              `}
                            >
                              <div
                                className={css`
                                  text-align: center;
                                  font-weight: bold;
                                  font-size: 24px;
                                  margin-top: 12px;
                                  margin-bottom: 12px;
                                `}
                              >
                                Surest Flexible Benefits
                              </div>
                              <div
                                className={css`
                                  text-align: center;
                                  font-size: 12px;
                                  margin-top: 12px;
                                  margin-bottom: 12px;
                                `}
                              >
                                If you are anticipating any of the below procedures, you will want to log into the
                                Surest app to see the additional costs associated:
                              </div>
                              <div
                                className={css`
                                  display: flex;
                                  justify-content: center;
                                `}
                              >
                                <a
                                  href="https://join.surest.com/"
                                  target="_blank"
                                  className={css`
                                    text-decoration: none;
                                    padding: 12px;
                                    button {
                                      max-width: 300px !important;
                                    }
                                  `}
                                >
                                  {' '}
                                  <Button secondary>Log in to Surest to see Costs</Button>
                                </a>{' '}
                              </div>

                              <div
                                className={css`
                                  text-align: left;
                                  font-size: 12px;
                                  margin-top: 12px;
                                  margin-bottom: 12px;
                                  font-weight: bold;
                                `}
                              >
                                Ankle Arthroscopy and Ligament Repair
                                <br />
                                Ankle Replacement
                                <br />
                                Ankle and Foot Bone Fusion
                                <br />
                                Back Surgery, Cervical Spine Disc Decompression
                                <br />
                                Back Surgery, Cervical Spine Fusion
                                <br />
                                Breast Reduction Surgery
                                <br />
                                Bunionectomy and Hammertoe Surgery
                                <br />
                                Cardiac Ablation
                                <br />
                                Carotid Endarterectomy and Stents
                                <br />
                                Cataract Surgery
                                <br />
                                Coronary Artery Bypass Graft Surgery
                                <br />
                                Coronary Catheterization and Percutaneous Coronary Interventions
                                <br />
                                Ear Tubes
                                <br />
                                Elbow Arthroscopy and Tenotomy
                                <br />
                                Elbow Replacement and Revision
                                <br />
                                Fibroid Removal (Myomectomy)
                                <br />
                                Gallbladder Removal Surgery (Cholecystectomy)
                                <br />
                                Ganglion Cyst Surgery
                                <br />
                                Hernia Repair
                                <br />
                                Hip Arthroscopy and Repair
                                <br />
                                Hip Replacement and Revision
                                <br />
                                Hysterectomy
                                <br />
                                Hysteroscopy and Endometrial Ablation
                                <br />
                                Kidney Stone Ablation and Removal (Lithotripsy)
                                <br />
                                Knee Arthroscopy and Repair
                                <br />
                                Knee Replacement and Revision
                                <br />
                                Lumbar Spine Disc Decompression
                                <br />
                                Lumbar Spine Fusion
                                <br />
                                Morton’s Neuroma Surgery
                                <br />
                                Pacemakers and Defibrillators
                                <br />
                                Plantar Fasciitis Surgery
                                <br />
                                Prostate Removal Surgery
                                <br />
                                Reflux and Hiatal Hernia Surgery
                                <br />
                                Shoulder Arthroscopy and Repair
                                <br />
                                Shoulder Replacement and Revision
                                <br />
                                Sinus and Nasal Septum Surgery
                                <br />
                                Sling Surgery for Female Urinary Incontinence
                                <br />
                                Spinal Ablation and Neurostimulators
                                <br />
                                Tonsillectomy and Adenoidectomy
                                <br />
                                Upper GI Endoscopy
                                <br />
                                Valve Replacement
                                <br />
                                Wrist Arthroscopy and Repair
                                <br />
                                Wrist and Hand Replacement and Revision
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      <MedicalDetails productId={selectedRecommendation.Plan?.ID} />
                    </div>
                    <div
                      className={css`
                        margin-bottom: 18px;
                        margin-top: 20px;
                        font-size: 11px;
                        padding-left: 12px;
                        padding-right: 12px;
                      `}
                    >
                      *This is not a full or complete list of plan payments or coverages. Please refer to your Summary
                      Plan Description for a full list of plan features.
                    </div>
                  </div>
                </div>
              </div>
            </Layer>
          </Grommet>
        )}

        {this.props.showAllPlans && (
          <Grommet theme={theme}>
            <Layer
              responsive={true}
              position="center"
              modal
              margin="small"
              onClickOutside={() => this.props.setShowAllPlans(false)}
              onEsc={() => this.props.setShowAllPlans(false)}
              className={css`
                width: 600px;
                font-family: Roboto, Montserrat, sans-serif !important;
                border-radius: 16px !important;
              `}
            >
              <div
                className={css`
                  padding: 32px;
                  ${smallScreen} {
                    padding: 16px;
                  }
                `}
              >
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <Text h1>Other Medical Plans</Text>
                  <Button styles="icon" onClick={() => this.props.setShowAllPlans(false)}>
                    <X />
                  </Button>
                </div>
                <div
                  className={css`
                    max-height: 500px;
                    overflow: auto;
                    margin: 32px 0;
                  `}
                >
                  {this.props.allMedicalPlans.map((rec) => {
                    return (
                      <div
                        onClick={() => {
                          this.props.setSelectedMedicalRec(rec);
                          this.props.setMedicalType('medical');
                          this.props.setShowAllPlans(false);
                        }}
                        data-ph-capture-attribute-source={posthogAttrs?.types?.RECOMMENDATIONS}
                        data-ph-capture-attribute-action={posthogAttrs?.actions?.SELECT_MEDICAL}
                        data-ph-capture-attribute-product-id={rec?.Plan?.ID}
                        data-ph-capture-attribute-product-type={rec.Plan?.Type}
                        className={css`
                          border-radius: 16px;
                          ${rec.Plan.ID === selectedRecommendation.Plan.ID
                            ? `
                            border: 4px solid ${
                              this.props.designStyles?.Theme?.Body?.ButtonColor
                                ? this.props.designStyles?.Theme?.Body?.ButtonColor
                                : '#25282D'
                            };
                          `
                            : `
                            border: 1px solid ${colors.gray[300]};
                            :hover {
                              outline: 1px solid ${colors.black};
                              outline-offset: -1px;
                            }`};
                          margin-bottom: 16px;
                          background-color: white;
                          padding: 24px;
                          cursor: pointer;

                          ${smallScreen} {
                            padding: 16px;
                          }
                        `}
                      >
                        <div
                          className={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                          `}
                        >
                          <div>
                            {rec?.Plan?.IsMultiCarrier ? (
                              <div
                                className={css`
                                  display: flex;
                                  justify-content: left;
                                  align-items: center;
                                `}
                              >
                                <CarrierLogo carrierID={rec?.Plan?.CarrierID} maxWidth="150px" position="center" />
                                <div
                                  className={css`
                                    border-left: 2px solid var(--button-background-color);
                                    height: 60px;
                                    padding-right: 4px;
                                    padding-left: 8px;
                                  `}
                                />
                                <CarrierLogo carrierID={rec?.Plan?.MultiCarrierID} maxWidth="150px" position="center" />
                              </div>
                            ) : (
                              <CarrierLogo carrierID={rec?.Plan?.CarrierID} maxWidth="150px" position="center" />
                            )}
                            <Text h2>{rec.Plan.Name}</Text>
                          </div>
                          {!this.props.hideEstimatedCost ? (
                            <div>
                              <Text
                                h2
                                css={`
                                  text-align: right;
                                `}
                              >
                                {this.getTotalCombinedCost(rec) < 0
                                  ? `$0`
                                  : `${formatCurrency(this.getTotalCombinedCost(rec))}`}
                              </Text>
                              <Text
                                label
                                bold
                                className={css`
                                  text-align: right;
                                  padding-top: 8px;
                                  ${smallScreen} {
                                    font-size: 10px;
                                  }
                                `}
                              >
                                <Tooltip
                                  PopperProps={{ style: { zIndex: 200000 } }}
                                  placement="right"
                                  title={costExplanation}
                                >
                                  <HelpOutlineIcon
                                    style={{
                                      fontSize: 18,
                                      top: '3px',
                                      marginLeft: '2px',
                                      position: 'relative',
                                    }}
                                  />
                                </Tooltip>
                                Estimated net costs{' '}
                              </Text>

                              {this.props.showMonthlyCosts ? (
                                <Text
                                  className={css`
                                    text-align: right;
                                    ${smallScreen} {
                                      font-size: 10px;
                                      line-height: 10px;
                                    }
                                  `}
                                >
                                  (monthly average)
                                </Text>
                              ) : (
                                <Text
                                  className={css`
                                    text-align: right;
                                    font-size: 14px;
                                    color: #66737f;
                                    ${smallScreen} {
                                      font-size: 10px;
                                      line-height: 10px;
                                    }
                                  `}
                                >
                                  (Annual)
                                </Text>
                              )}
                            </div>
                          ) : (
                            <div
                              className={css`
                                flex-grow: 1;
                              `}
                            >
                              <div
                                className={css`
                                  color: #25282d;
                                  font-size: 24px;
                                  text-align: right;
                                  line-height: 1.5;
                                  ${smallScreen} {
                                    font-size: 14px;
                                  }
                                `}
                              >
                                {getProductPremium({
                                  cost: rec?.Plan?.Cost,
                                  key: relevantTier,
                                  interval: this.props.displaySettings.premiumInterval,
                                })}
                              </div>
                              <Text
                                label
                                className={css`
                                  width: 80px;
                                  margin-left: auto;
                                  text-align: right;
                                  padding-top: 8px;
                                  line-height: 12px;
                                  font-size: 12px;
                                `}
                              >
                                {this.premiumDescription()}
                              </Text>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Layer>
          </Grommet>
        )}
      </motion.div>
    );
  }
}
