import { TemplateViewer } from './template-viewer';
import { Viewer } from './viewer';
import { PDFViewer } from './pdf/PDFViewer';
import { useState, useEffect } from 'react';
import posthog from 'posthog-js';
import { useFeatureFlagPayload } from 'posthog-js/react';

export const ViewerRenderer = () => {
  const [path, id, pageId] = window.location.pathname.substring(1).split('/');
  const [currentLanguage, setCurrentLanguage] = useState('');
  const languageIntegrationFF = useFeatureFlagPayload('integrated-language-translation');

  useEffect(()=>{
    // don't set language from localStorage if the language feature-flag is off, because the user won't be able to change it on the current guide
    if (!languageIntegrationFF?.value) {
      setCurrentLanguage('');
      return;
    }

    if (!currentLanguage) {
      // check if we should load the current language from localStorage
      const storedLang = localStorage.getItem('wglang');
      if (storedLang) {
        setCurrentLanguage(storedLang);
      }
    } else {
      posthog.register({
        currentLanguage: currentLanguage
      });
    }
  }, [currentLanguage, languageIntegrationFF?.value]);

  const changeLanguageCallback = (languageCode) => {
    setCurrentLanguage(languageCode); // This will intentionally trigger a re-render of the Viewer component because we are passing the currentLanguage as a prop to it.
    // Sometimes it works without a re-render, but the interaction between WeGlot's script, and the react app occasionally won't register the language switch till a reload.
  };
  switch (path) {
    case 'brite-template-preview':
      return id ? <TemplateViewer templateId={id} /> : null;
    case 'pdf-preview':
      return id ? <PDFViewer guideId={id} pageId={pageId} /> : null;
    default:
      return <Viewer key={currentLanguage} changeLanguageCallback={changeLanguageCallback} />;
  }
};
